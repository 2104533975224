<template>
  <div class="container mx-auto px-4" :class="{'pt-12' : paddingTop, 'pb-8': paddingBottom}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    paddingTop: {
      type: Boolean,
      default: true
    },
    paddingBottom: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>