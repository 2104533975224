<template>
  <div>
    <div class="flex justify-between items-center">
      <h1 class="text-flamingo-800 font-semibold text-2xl">{{ room.name }}</h1>
      <div class="flex-shrink-0 text-xl">
        <font-awesome-icon icon="users"></font-awesome-icon>
        <span class="ml-2">{{$t('NEW_RESERVATION.CAPACITY')}} : {{ room.limit_of_persons }}</span>
      </div>
    </div>
    <div class="md:grid grid-cols-2 my-5">
      <div class="flex flex-col mt-4 text-gray-600  space-y-2">
        <div class="flex items-center space-x-4">
          <font-awesome-icon icon="map-marker-alt" class="text-xl"></font-awesome-icon>
          <span>{{ room.building.name }} ({{ room.building.city }})</span>
        </div>
        <div v-if="date" class="flex items-center space-x-4">
          <font-awesome-icon icon="calendar-alt" class="text-xl"></font-awesome-icon>
          <span>{{date.date}} || {{date.timeStart}} - {{date.timeEnd}}</span>
        </div>
      </div>
      <div class="flex flex-col text-gray-600 md:space-y-2 w-full md:w-4/5 mt-5 md:mt-0">
        <div class="space-y-2">
          <div class="md:grid flex grid-cols-2 space-x-4">
            <span class="font-serif md:text-right mr-auto md:mr-0">{{$t('MAKE_RESERVATION.PRICE')}}</span>
            <badge variant="primary" v-if="room.booking_info.pricing_type === 'free'">{{$t('MAKE_RESERVATION.FREE')}}</badge>
            <badge variant="primary" v-else>{{ room.booking_info.price }}€ / {{ getBookingPricingType }}</badge>
          </div>
          <div class="md:grid flex grid-cols-2 space-x-4">
            <span class="font-serif md:text-right mr-auto md:mr-0 ">{{$t('MAKE_RESERVATION.DEPOSIT')}}</span>
            <badge variant="secondary" v-if="room.booking_info.deposit_needed === 0">{{$t('MAKE_RESERVATION.NO_DEPOSIT')}}</badge>
            <badge variant="secondary" v-else>{{ room.booking_info.deposit_amount }}€</badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Badge from "@/components/elements/ui/Badge.vue";

export default {
  name: "RoomInformation",
  components: {Badge},
  props: {
    room: {
      type: Object,
      required: true
    },
    date: {
      type: Object
    },
  },
  computed: {
    getBookingPricingType() {
      if (this.room.booking_info.pricing_type === 'per_day')
        return this.$t('NEW_RESERVATION.PER_DAY')
      else if (this.room.booking_info.pricing_type === 'per_hour')
        return this.$t('NEW_RESERVATION.PER_HOUR')
      else if (this.room.booking_info.pricing_type === 'per_reservation')
        return this.$t('NEW_RESERVATION.PER_RESERVATION')
      return ''
    },
  },
}
</script>

<style scoped>

</style>