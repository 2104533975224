<template>
  <input-element :name="name"
                 :icon="icon"
                 type="number"
                 :unsigned="unsigned"
                 :label-class="labelClass"
                 v-model="content"
                 @input="setValue(content)"
                 @enter="$emit('enter')">

    <slot></slot>
  </input-element>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";
export default {
  name: "NumberSelectorElement",
  components: {InputElement},
  data: () => ({
    content: 0,
    placeholder: ''
  }),
  props: {
    value: {
     // validator: prop => typeof prop === 'number' || prop === null,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false
    },
    unsigned: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value: function (val) {
      if(this.unsigned) {
        if(val < 0)
          val = 0;
      }
      this.setValue(val);
    }
  },
  methods: {
    setValue(val) {
      val = Number(val);

      this.content = val;
      this.$emit('input', val)
    }
  },
}
</script>

<style scoped>

</style>