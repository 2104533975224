<template>
  <div class="flex flex-col">
    <h1 class="flex-1 text-center md:text-left text-flamingo-800 text-3xl font-serif">{{ title }}</h1>
    <span class="flex-1 text-center md:text-left text-gray-400 font-sans">{{ subtitle }}</span>
  </div>
</template>

<script>
export default {
  name: "PageHeading",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
  }
}
</script>

<style scoped>

</style>