<template>
<div></div>
</template>

<script>
import AuthRepository from "../repositories/AuthRepository";

export default {
  name: "VerifyEmail",
  mounted() {
    this.verifyEmail()
  },
  methods: {
    verifyEmail() {
      AuthRepository.verifyEmail(this.$route.params.id, this.$route.params.hash)
          .then((() => {
            this.$router.push({name:'account'})
          })).catch(() => {
      }).finally(
      );
    }
  }
}
</script>

<style scoped>

</style>