import axios from 'axios';
import {vue} from '@/main';
import store from "@/store/store";


const baseURL = process.env.VUE_APP_API_URL

const axiosInstance = axios.create({
    baseURL: baseURL + '/api/v1',
});

/*
  Request Interceptor
 */
axiosInstance.interceptors.request.use((config) => {
    //  Generate cancel token source
    let source = axios.CancelToken.source();
    config.cancelToken = source.token;
    store.commit('ADD_CANCEL_TOKEN', source);

    config.headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Language': vue.$i18n.locale,
        'Access-Control-Allow-Origin': baseURL,
        'Sec-Fetch-Site': 'cross-site'
    }

    if (store.getters.getAuthToken !== null) {
        config.headers.Authorization = 'Bearer ' + store.getters.getAuthToken;
        config.withCredentials = true;
    }

    return config;
}, error => {
    Promise.reject(error)
});


/*
  Response Interceptor
 */
axiosInstance.interceptors.response.use((response) => {
    if (typeof response.data !== 'undefined') {
        if (typeof response.data.message !== 'undefined') {
            vue.$notify(
                {group: "success", title: vue.$t('TOAST.SUCCESS'), text: response.data.message},
                4000
            );
        }
    }

    return response;
}, (error) => {

    if (error.response) {
        if (typeof error.response.data !== 'undefined') {
            if (typeof error.response.data.error !== 'undefined') {
                vue.$store.commit('SET_ERROR_MESSAGE', error.response.data.error);
            }
        }
    }

    switch (error.response.status) {
        case 400:
            return Promise.reject(error);
        case 401:
            vue.$store.commit('LOGOUT');
            break;
        case 403:
            if (error.response.data.error) {
                vue.$store.commit('SET_ERROR_MESSAGE', error.response.data.error);
            }
            break;
        case 404:
            break;
        case 422:
            if (error.response.data.errors) {
                vue.$store.commit('SET_VALIDATION_ERRORS', error.response.data.errors);
            }
            break;
        case 500:
            if (error.response.data?.message !== undefined) {
                vue.$notify(
                    { group: "error", title: vue.$t('TOAST.ERROR'), text: vue.$t('TOAST.ERROR_MESSAGE')},
                    4000
                );
            }
            break;

    }

    return Promise.reject(error);
});

export default axiosInstance;
