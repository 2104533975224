<template>
  <div>
    <div class="text-gray-500 text-center text-3xl mb-10 font-serif">
      <small>{{ $t('ACCOUNT_PROBLEMS.ACCOUNT_PENDING_CONFIRMATION.TITLE') }}</small>
    </div>

    <p v-html="$t('ACCOUNT_PROBLEMS.ACCOUNT_PENDING_CONFIRMATION.TEXT')"></p>

    <div class="mt-10 text-center">
      <account-problem-logout-button></account-problem-logout-button>
    </div>

  </div>
</template>
<script>
import AccountProblemLogoutButton from "@/components/auth/accountProblem/Includes/AccountProblemLogoutButton";
export default {
  name: "AccountUnconfirmed",
  components: {AccountProblemLogoutButton},
}
</script>
