<template>
  <div class="h-full flex flex-col">
    <div v-if="!makingReservation" class="md:h-52  h-56 pt-10 px-5 bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
      <page-heading class="h-20" :title="$t('NEW_RESERVATION.TITLE')" :subtitle="$t('NEW_RESERVATION.SUBTITLE')"/>
      <div  class=" flex justify-center">
        <button class=" mx-5 font-serif rounded-full text-white text-lg w-full md:w-40 py-1 transition focus:outline-none  select-none"
                :class="[mode === 'date' ?
          'bg-flamingo-800 md:hover:bg-flamingo-900' :
          'bg-olive-300 hover:bg-olive-400']" @click="mode='date'">Date
        </button>
        <button class=" mx-5 font-serif rounded-full text-white text-lg w-full md:w-40 py-1 transition focus:outline-none  select-none"
                :class="[mode === 'calendar' ?
          'bg-flamingo-800 hover:bg-flamingo-900' :
          'bg-olive-300 hover:bg-olive-400']" @click="mode='calendar'">Calendrier
        </button>
      </div>
    </div>

    <div class="flex-grow w-full bg-gray-200   md:pt-10 pt-16">
      <transition
          mode="out-in"
          enter-active-class="transition-all transition-fastest ease-out-quad"
          leave-active-class="transition-all transition-faster ease-in-quad"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
      >

        <calendar-view v-if="mode ==='calendar'" @room-set="roomSet"></calendar-view>
        <date-view v-else-if="mode ==='date'" @room-set="roomSet"></date-view>
      </transition>
    </div>
  </div>
</template>

<script>
import PageHeading from "@/components/elements/PageHeading";
import CalendarView from "./CalendarView";
import DateView from "./DateView";

export default {
  name: "NewReservation",
  components: {
    PageHeading, CalendarView, DateView
  },
  data: () => ({
    mode: 'date',
    makingReservation: false,
    backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),

  }),
  methods: {
    roomSet() {
      this.makingReservation = !this.makingReservation
    }
  },
  computed: {}
}
</script>

<style scoped>

</style>