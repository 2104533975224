import '@/assets/scss/main.scss'

//Plugins
import '@/plugins/Dayjs';

import Vue from 'vue'
import i18n from './i18n'

import {library} from '@fortawesome/fontawesome-svg-core'
import {faCircle, faDotCircle, faFilePdf} from '@fortawesome/free-regular-svg-icons';
import {
    faSignOutAlt,
    faArrowRight,
    faArrowLeft,
    faTimes,
    faBars,
    faEnvelope,
    faPhone,
    faUser,
    faLock,
    faUsers,
    faExclamationTriangle,
    faCalendarAlt,
    faSearch,
    faMapMarkerAlt,
    faPlus,
    faPen,
    faCheck,
    faClock,
    faCalendar,
    faFileDownload,
    faChevronDown

} from '@fortawesome/free-solid-svg-icons'

library.add(faChevronDown,faFileDownload, faCalendar, faClock, faFilePdf, faSignOutAlt, faArrowRight, faArrowLeft, faCircle, faDotCircle, faTimes, faBars, faEnvelope, faPhone, faUser, faLock, faUsers, faExclamationTriangle, faCalendarAlt, faSearch, faMapMarkerAlt, faPlus, faPen, faCheck);

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import router from "@/router/router";
import store from "@/store/store";

import VueProgressBar from 'vue-progressbar'

import Notifications from "vt-notifications";
Vue.use(Notifications);

Vue.use(VueProgressBar, {
    color: 'rgb(242, 179, 36)',
    failedColor: 'red',
    height: '6px'
});

Vue.config.productionTip = false
import App from './App.vue'

let vue = new Vue({
    render: h => h(App),
    router,
    store,
    i18n,
}).$mount('#app')

export {vue};