<template>
  <span>{{ $t('MAKE_RESERVATION.RECURRENCE.' + type, {day: getDay, number: getOrdinal, dayNumber: getDayNumber, month: getMonth}) }}</span>
</template>

<script>
export default {
  name: "LocalizedRecurrenceDates",
  props: {
    date: {
      type: String,
      required: true
    },
    type:{
      type :String,
      required: true
    }
  },
  computed: {
    getMinDate() {
      return this.$dayjs(this.date, 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm');
    },

    getDay() {
      let day = this.$dayjs(this.date, 'DD.MM.YYYY').get('day')
      if (day === 0) return this.$t('DAY.SUNDAY')
      else if (day === 1) return this.$t('DAY.MONDAY')
      else if (day === 2) return this.$t('DAY.TUESDAY')
      else if (day === 3) return this.$t('DAY.WEDNESDAY')
      else if (day === 4) return this.$t('DAY.THURSDAY')
      else if (day === 5) return this.$t('DAY.FRIDAY')
      else if (day === 6) return this.$t('DAY.SATURDAY')
      return day
    },

    getDayNumber() {
      return this.$dayjs(this.date, 'DD.MM.YYYY').get('date')
    },
    // @formatter:off
    getMonth() {
      let month = this.$dayjs(this.date,'DD.MM.YYYY').get('month')
      switch (month) {
        case 0 : return this.$t('MONTH.JANUARY')
        case 1 : return this.$t('MONTH.FEBRUARY')
        case 2 : return this.$t('MONTH.MARCH')
        case 3 : return this.$t('MONTH.APRIL')
        case 4 : return this.$t('MONTH.MAI')
        case 5 : return this.$t('MONTH.JUIN')
        case 6 : return this.$t('MONTH.JULY')
        case 7 : return this.$t('MONTH.AUGUST')
        case 8 : return this.$t('MONTH.SEPTEMBER')
        case 9 : return this.$t('MONTH.OCTOBER')
        case 10 : return this.$t('MONTH.NOVEMBER')
        case 11 : return this.$t('MONTH.DECEMBER')
        default : return ''
      }
    },
// @formatter:on
    getOrdinal() {
      let occurrence = Math.ceil(this.$dayjs(this.date, 'DD.MM.YYYY').get('date') / 7)
      if (occurrence === 1) return this.$t('MAKE_RESERVATION.RECURRENCE.FIRST')
      else if (occurrence === 2) return this.$t('MAKE_RESERVATION.RECURRENCE.SECOND')
      else if (occurrence === 3) return this.$t('MAKE_RESERVATION.RECURRENCE.THIRD')
      else if (occurrence === 4) return this.$t('MAKE_RESERVATION.RECURRENCE.FOURTH')
      return ''
    }
  }
}
</script>

<style scoped>

</style>