<template>
  <button
      class="bg-flamingo-800 text-white font-serif text-lg active:bg-gray-700 text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
      type="button"
      style="transition: all 0.15s ease 0s;"
      @click="onButtonClicked">

    <svg class="animate-spin inline-block -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading"
         key="loading_icon">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>

    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ActionButton",
  data: () => ({
    isLoading: false,
  }),
  methods: {
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    setLoadingState(state) {
      this.isLoading = state;
    },
    onButtonClicked($event) {
      if (!this.loading)
        this.$emit('click', $event)
    }
  },
  computed: {
    loading() {
      return this.isLoading;
    }
  }
}
</script>

<style scoped>

</style>