<template>
  <div class="bg-white w-full rounded-md shadow-xl">
    <div class="p-4 pl-6 pr-6 pb-2">
      <h3 class="text-black text-xl font-serif font-semibold">
        {{ title }}
      </h3>
      <div class="text-gray-500" v-if="subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="w-full h-0.5 bg-gray-100" v-if="title || subtitle"></div>
    <div class="p-4 pl-6 pr-6">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    title: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped>

</style>