<template>
    <div class="flex cursor-pointer select-none p-5 " @click.prevent="$emit('checkbox-clicked',id)">
      <font-awesome-icon v-if="content"  :icon="['far','dot-circle']" size="lg" class="inline-block align-middle text-flamingo-800" ></font-awesome-icon>
      <font-awesome-icon v-else :icon="['far','circle']" size="lg"  class="inline-block align-middle text-gray-400" ></font-awesome-icon>
    </div>
</template>

<script>
export default {
  name: "CustomCheckboxElement",
  data: () => ({
    content: false,
  }),
  props: {
    id: {
      type: Number,
      required: true,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  watch: {
    selected: function (val) {
      this.setValue(val);
    }
  },
  methods: {
    setValue(val) {
       this.content = val;
    },
  },
  mounted() {
     if (this.selected)
       this.setValue(this.selected)
  },
}
</script>

<style scoped>

</style>