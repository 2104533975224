<template>
  <div class="dropdown" v-if="options">
    <label class="block" :class="[ hasCustomLabelClasses ? labelClass : 'text-gray-700 text-sm mb-2']"
           :for="name.toLowerCase()">
      <span class="ml-0.5">
        <slot></slot>
      </span>
    </label>
    <!-- Dropdown Input -->
    <div class="relative text-gray-600 focus-within:text-flamingo-800 w-full">

      <div class="absolute inset-y-0 left-0 flex items-center pl-2" v-if="hasIcon">
        <div class="p-1 focus:outline-none focus:shadow-outline">
          <font-awesome-icon :icon="icon" class="ml-0.5 mr-1.5"></font-awesome-icon>
        </div>
      </div>
      <input class="w-full pl-3 py-2 text-sm text-black bg-white rounded border-2 border-transparent
             transition focus:outline-none focus:border-opacity-50 focus:border-flamingo-900 focus:bg-white focus:text-flamingo-800 pr-3 shadow"
             :name="name"
             :class="[hasError ? 'ring-red-400 ring' : 'ring-flamingo-700', hasIcon ? 'pl-10' : 'pl-3']"
             @focus="showOptions()"
             @blur="exit()"
             @keyup="keyMonitor"
             v-model="searchFilter"
             :disabled="disabled"
             :placeholder="placeholder"
             autocomplete="new"/>
    </div>
    <div class="text-center" v-show="hasError">
      <span class="text-red-600 text-xs">
      {{ getFirstErrorMessage }}
    </span>
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-content"
         v-show="optionsShown">
      <div
          class="dropdown-item"
          @mousedown="selectOption(option)"
          v-for="(option, index) in filteredOptions"
          :key="index">
        {{ option.name || option.id || '-' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  template: 'Dropdown',
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    options: {
      type: Array,
      required: false,
      default() {
        return {}
      },
      note: 'Options of dropdown. An array of options with id and name',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false
    },
    defaultValue: {
      type: Number,
      required: false,
      default: null,
      note: 'Default Selected'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: 'Max items showing'
    },
    icon: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      searchFilter: '',
      content: '',
      placeholderValue: ''
    }
  },
  created() {
    if (this.defaultValue) {
      this.setDefault(this.defaultValue);
    }
    //this.$emit('selected', this.selected);
  },
  mounted() {
    // Only works if the default <slot> is text.
    if (this.$slots.default && this.$slots.default[0]?.elm?.nodeValue !== undefined)
      this.placeholderValue = this.$slots.default[0].elm.nodeValue;
    else if (this.placeholder !== undefined) {
      this.placeholderValue = this.placeholder;
    }
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, 'gi');
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.name.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
    hasCustomLabelClasses() {
      return this.labelClass !== undefined;
    },
    hasIcon() {
      return this.icon !== undefined;
    },
    hasError() {
      return this.getValidationName in this.validationErrors;
    },
    getValidationName() {
      return this.name;
    },
    validationErrors() {
      return this.$store.getters.getValidationErrors;
    },
    getFirstErrorMessage() {
      if (!this.hasError)
        return "";

      return this.validationErrors[this.getValidationName][0];
    },
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.searchFilter = this.selected.name;
      this.$emit('selected', this.selected);
    },

    setDefault(id) {
      this.selectOption(this.options.find(element => element.id === id))
    },
    showOptions() {
      if (!this.disabled) {
        if (this.defaultValue && this.defaultValue.name) {
          this.searchFilter = this.defaultValue.name;
        }
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        this.searchFilter = '';
      } else {
        this.searchFilter = this.selected.name;
      }
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    }
  },
  watch: {
    searchFilter() {
      if (this.filteredOptions.length === 0 && !this.defaultValue)
        this.selected = {};

      this.$emit('filter', this.searchFilter);
    },

  }
};
</script>


<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;

  .dropdown-input {
  }

  .dropdown-content {
    position: absolute;
    background-color: #fff;
    min-width: 100%;
    max-width: 100%;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1;

    .dropdown-item {
      color: black;
      font-size: .7em;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;

      &:hover {
        background-color: #e7ecf5;
      }
    }
  }

  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>