<template>
  <section class="w-full h-full bg-white bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">

        <div class="w-full lg:w-6/12 px-4 mt-8">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-50 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-6">

              <div class="text-gray-500 text-center text-3xl mb-10 font-serif">
                <small>{{ $t('REGISTER.TITLE') }}</small>
              </div>

              <form>

                <div class="grid grid-cols-1 md:grid-cols-2  gap-6 mb-5">
                  <input-element name="last_name" autocomplete="family-name" type="text" icon="user" v-model="last_name" @enter="login">
                    {{ $t('REGISTER.LAST_NAME') }}
                  </input-element>

                  <input-element name="first_name" autocomplete="given-name" type="text" icon="user" v-model="first_name" @enter="login">
                    {{ $t('REGISTER.FIRST_NAME') }}
                  </input-element>
                </div>

                <input-element class="mb-5" name="email" autocomplete="email" icon="envelope" type="email" v-model="email" @enter="login">
                  {{ $t('REGISTER.EMAIL') }}
                </input-element>

                <div class="grid grid-cols-1 md:grid-cols-2  gap-6 mb-5">
                  <drop-down-element
                      class=""
                      :options="callingCodes"
                      :defaultValue="phone_country_id"
                      v-on:selected="validateCallingCodeSelection"
                      name="phone_country_id"
                      :maxItem="10"
                      icon="phone"
                      :placeholder=" $t('PROFILE.INDICATIF')">
                    {{ $t('PROFILE.INDICATIF') }}
                  </drop-down-element>
                  <input-element name="phone_number" autocomplete="phone" icon="phone" type="number" v-model="phone_number"
                                 @enter="login">{{ $t('PROFILE.PHONE') }}
                  </input-element>
                </div>


                <div class="grid grid-cols-1 md:grid-cols-2  gap-6 mb-5">
                  <drop-down-element
                      class=""
                      :options="callingCodes"
                      :defaultValue="phone_office_country_id"
                      v-on:selected="validateOfficeCallingCodeSelection"
                      name="phone_office_country_id"
                      :maxItem="10"
                      icon="phone"
                      :placeholder=" $t('PROFILE.INDICATIF')">
                    {{ $t('PROFILE.INDICATIF') }}
                  </drop-down-element>
                  <input-element name="phone_number" autocomplete="phone" icon="phone" type="number" v-model="phone_number_office"
                                 @enter="login">{{ $t('PROFILE.PHONE_OFFICE') }}
                  </input-element>
                </div>


                <div class="grid grid-cols-1 md:grid-cols-2  gap-6 mb-5">
                  <drop-down-element
                      class=""
                      :options="callingCodes"
                      :defaultValue="phone_fix_country_id"
                      v-on:selected="validateFixCallingCodeSelection"
                      name="phone_fix_country_id"
                      :maxItem="10"
                      icon="phone"
                      :placeholder=" $t('PROFILE.INDICATIF')">
                    {{ $t('PROFILE.INDICATIF') }}
                  </drop-down-element>
                  <input-element name="phone_number" autocomplete="phone" icon="phone" type="number" v-model="phone_number_fix"
                                 @enter="login">{{ $t('PROFILE.PHONE_FIX') }}
                  </input-element>
                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <input-element name="password" icon="lock" type="password" autocomplete="new-password" v-model="password" @enter="login">
                    {{ $t('REGISTER.PASSWORD') }}
                  </input-element>

                  <input-element name="password_confirmation" icon="lock" type="password" autocomplete="new-password" v-model="password_confirmation"
                                 @enter="login">
                    {{ $t('REGISTER.PASSWORD_CONFIRM') }}
                  </input-element>
                </div>
                <div class="text-center mt-14">
                  <action-button @click="login" ref="registerButton">
                    {{ $t('REGISTER.REGISTER_BUTTON') }}
                  </action-button>
                </div>
              </form>
              <div class="text-red-600 text-center mt-5" v-if="captchaError">
                {{captchaError}}
              </div>
            </div>
            <google-re-captcha-v3
                v-model="g_recaptcha_response"
                ref="captcha"
                id="contact_us_id"
                inline
                action="register"
            ></google-re-captcha-v3>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";
import ActionButton from "@/components/elements/forms/ActionButton";
import DropDownElement from "../elements/forms/DropDownElement";
import GoogleReCaptchaV3 from "../GoogleReCaptchaV3";

export default {
  name: "register-page",
  data: () => ({
    backgroundImagePath: require('@/assets/images/auth/mamerbg.jpg'),

    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    customer_type_id: null,
    institution_name: '',

    phone_country_id: null,
    phone_fix_country_id: null,
    phone_office_country_id: null,

    phone_number: null,
    phone_number_fix: null,
    phone_number_office: null,
    countries: null,
    g_recaptcha_response: null
  }),
  components: {
    ActionButton,
    InputElement,
    DropDownElement,
    GoogleReCaptchaV3
  },
  computed: {
    callingCodes() {
      if (this.countries)
        return this.countries.map((e) => {
          return {id: e.id, name: e.name + ' +' + e.calling_code + ''}
        })
      return null
    },
    captchaError() {
      if (this.$store.getters.getValidationErrors.g_recaptcha_response)
        return this.$store.getters.getValidationErrors.g_recaptcha_response[0]
      return null
    }
  },
  methods: {
    login() {
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone_country_id: this.phone_country_id,
        phone_fix_country_id: this.phone_fix_country_id,
        phone_office_country_id: this.phone_office_country_id,
        phone_number: this.phone_number,
        phone_number_office: this.phone_number_office,
        phone_number_fix: this.phone_number_fix,
        password: this.password,
        password_confirmation: this.password_confirmation,
        g_recaptcha_response: this.g_recaptcha_response
      };

      if (this.selectedTypeRequiresInstitutionName) {
        data.institution_name = this.institution_name;
      }

      this.$refs.registerButton.setLoadingState(true);

      this.$store.dispatch('CLEAR_VALIDATION_ERRORS')

      this.$store.dispatch("REGISTER_USER", data).then(() => {
        // this.$refs.captcha.execute()
        this.$refs.registerButton.setLoadingState(false);
        this.$router.push({name: 'profile'});
      }).catch(() => {
        // this.$refs.captcha.execute()
        this.$refs.registerButton.setLoadingState(false)
      })
    },
    getCountries() {
      this.$store.dispatch('GET_COUNTRIES').then(
          this.countries = this.$store.getters.getCountries
      )
    },
    validateCallingCodeSelection(e) {
      if (e)
        this.phone_country_id = e.id
    },
    validateFixCallingCodeSelection(e) {
      if (e)
        this.phone_fix_country_id = e.id
    },
    validateOfficeCallingCodeSelection(e) {
      if (e)
        this.phone_office_country_id = e.id
    },
  },
  mounted() {
    if (!this.$store.getters.getCountries)
      this.getCountries()
    this.countries = this.$store.getters.getCountries
  },
}
</script>
