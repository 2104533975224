<template>
  <div>
    <label class="block"
           :class="[hasError ? 'text-red-800' : '', hasCustomLabelClasses ? labelClass : 'text-gray-700 text-sm mb-2']"
           :for="name.toLowerCase()">
      <span :class="[hasIcon ? '' : 'ml-0.5']">
        <slot></slot>
      </span>
      <!-- Shown if @enter is event is not listened to in Dev mode -->
      <span v-if="showMissingEventListenerNotice" class="text-red-500 uppercase">Enter Event L. missing</span>
    </label>

    <div class="relative text-gray-600 focus-within:text-flamingo-800 w-full">

      <div class="absolute inset-y-0 left-0 flex items-center pl-2" v-if="hasIcon">
        <div class="p-1 focus:outline-none focus:shadow-outline">
          <font-awesome-icon :icon="icon" class="ml-0.5 mr-1.5"></font-awesome-icon>
        </div>
      </div>

      <textarea
             class="w-full py-2 text-sm text-black bg-white rounded border-2 border-transparent
             transition focus:outline-none focus:border-opacity-50 focus:border-flamingo-900 focus:bg-white focus:text-flamingo-800 pr-3 shadow "
             :class="[hasError ? 'ring-red-400 ring' : 'ring-flamingo-700', hasIcon ? 'pl-10' : 'pl-3']"
             :placeholder="placeholderValue"
             v-model="content"
             @input="setValue(content)"
             @keyup.enter="$emit('enter')">
        </textarea>
    </div>


    <div class="text-center" v-show="hasError">
      <span class="text-red-600 text-xs">
      {{ getFirstErrorMessage }}
    </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputElement",
  data: () => ({
    content: '',
    placeholderValue: ''
  }),
  props: {
    value: {
      required: true
    },
    name: {
      type: String,
      required: true
    },

    icon: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    autocomplete: {
      type: String,
      required: false
    },
    /**
     * ONLY WORKS ON type === 'number'
     * If set to true, only positive numbers are allowed
     */
    unsigned: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: function (val) {
      this.setValue(val);
    }
  },
  methods: {
    setValue(val) {

      this.content = val;
      this.$emit('input', val);
    },
  },
  mounted() {
    // Only works if the default <slot> is text.
    if (this.$slots.default && this.$slots.default[0]?.elm?.nodeValue !== undefined)
      this.placeholderValue = this.$slots.default[0].elm.nodeValue;
    else if (this.placeholder !== undefined) {
      this.placeholderValue = this.placeholder;
    }
    if (this.value)
      this.content = this.value
  },
  computed: {
    /**
     * Returns the name that is used to send the value to the server.
     * This can be useful for checking for validation errors.
     */
    getValidationName() {
      return this.name;
    },
    validationErrors() {
      return this.$store.getters.getValidationErrors;
    },
    hasError() {
      return this.getValidationName in this.validationErrors;
    },
    hasIcon() {
      return this.icon !== undefined;
    },
    getFirstErrorMessage() {
      if (!this.hasError)
        return "";

      return this.validationErrors[this.getValidationName][0];
    },
    hasCustomLabelClasses() {
      return this.labelClass !== undefined;
    },
    showMissingEventListenerNotice() {
      // Show notice only if not in production.
      return (!this.$listeners || !this.$listeners.enter) && window.webpackHotUpdate !== undefined;
    }
  }
}
</script>

<style scoped>

</style>