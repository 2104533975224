<template>
  <div class=" m-5 transition-all text-gray-600 overflow-hidden cursor-pointer"  @click.prevent="openAccordion">
    <div  class="flex items-center justify-between">
      <h4 :class="open ? 'text-flamingo-800' : ''">{{ item.question }}</h4>
      <font-awesome-icon icon="chevron-down" class=" transition-all transform" :class=" open ? 'rotate-180' : ''" key="static-icon"></font-awesome-icon>
    </div>
    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end">
      <p class="" v-show="open">
        {{ item.answer }}
      </p>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FaqListItem",
  data() {
    return {
      open: false,
    }
  },
  props: {
    item: {
      required: true,
      type: Object
    },
  },

  methods: {
    openAccordion() {
      this.$parent.$children.forEach((item) => {
        if (item.item.id === this.item.id) item.open = !item.open
        else item.open = false
      })
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  computed: {}
}
</script>

<style lang="scss" scoped>


.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>