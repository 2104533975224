import BaseRepository from "@/repositories/BaseRepository";

const url = '/account';

export default {


    updateUserData(payload) {
        return BaseRepository.post(`${url}/update`, payload);
    },

    getCountries(){
        return BaseRepository.get(`/countries`);
    },


    getProfiles(){
        return BaseRepository.get(`/account/profiles`);
    },

    updateProfile(payload){
        return BaseRepository.post(`/account/profile/update`, payload);
    },

    saveProfile(payload){
        return BaseRepository.post(`/account/profile/save`, payload);
    },

    deleteProfile(payload){
        return BaseRepository.delete('/account/profile/delete/'+payload);
    }



}

