<template>
  <div>
    <label class="block" :class="[hasError ? 'text-red-800' : '', hasCustomLabelClasses ? labelClass : 'text-gray-700 text-sm mb-2']"
           :for="name.toLowerCase()">
      <span :class="[hasIcon ? '' : 'ml-0.5']">
        {{ label || '' }}
      </span>
    </label>

    <div class="relative text-gray-600 focus-within:text-flamingo-800 w-full">

      <div class="absolute inset-y-0 left-0 flex items-center pl-2" v-if="hasIcon">
        <div class="p-1 focus:outline-none focus:shadow-outline">
          <font-awesome-icon :icon="icon" v-if="icon !== undefined" class="ml-0.5 mr-1.5"></font-awesome-icon>
        </div>
      </div>

      <select class="w-full py-2 text-sm bg-white rounded focus:outline-none focus:bg-white focus:text-flamingo-800 pr-3 shadow"
              :class="[hasError ? 'ring-red-400 ring' : 'ring-flamingo-700', hasIcon ? 'pl-10' : 'pl-3', content === '' ? 'text-gray-400' : 'text-black']"
              v-model="content" @change="setValue(content)"
      >
        <option value="" selected disabled hidden>{{ label }}</option>
        <slot></slot>
      </select>
    </div>

    <div class="text-center" v-show="hasError">
      <span class="text-red-600 text-xs">
      {{ getFirstErrorMessage }}
      </span>
    </div>

  </div>
</template>

<script>
export default {
  name: "SelectElement",
  data: () => ({
    content: '',
    placeholder: ''
  }),
  props: {
    value: {
      validator: prop => typeof prop === 'string' || typeof prop === 'number' || prop === null,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false
    },
  },
  watch: {
    value: function (val) {
      this.setValue(val);
    }
  },
  methods: {
    setValue(val) {
      if (val === '')
        val = null;

      this.content = val;
      this.$emit('input', val)
    }
  },
  mounted() {
    // Only works if the default <slot> is text.
    if (this.$slots.default !== undefined) {
      if (this.$slots.default[0]?.elm?.nodeValue !== undefined)
        this.placeholder = this.$slots.default[0].elm.nodeValue;
    }
    if (this.value)
      this.content = this.value;
  },
  computed: {
    /**
     * Returns the name that is used to send the value to the server.
     * This can be useful for checking for validation errors.
     */
    getValidationName() {
      return this.name;
    },
    validationErrors() {
      return this.$store.getters.getValidationErrors;
    },
    hasError() {
      return this.getValidationName in this.validationErrors;
    },
    hasIcon() {
      return this.icon !== undefined;
    },
    hasCustomLabelClasses() {
      return this.labelClass !== undefined;
    },
    getFirstErrorMessage() {
      if (!this.hasError)
        return "";

      return this.validationErrors[this.getValidationName][0];
    },
  }
}
</script>

<style scoped>

</style>