<template>
  <container :padding-top="false">
    <div class="px-10">
      <div class="bg-flamingo-800 w-full rounded-lg -mt-20 p-8">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
          <input-element name="room_filter" type="text"
                         label-class="text-white text-xl mb-2"
                         v-model="roomFilter" >
            {{ $t('CALENDAR.FILTER') }}
          </input-element>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-12 lg:grid-cols-11 mt-4 gap-4">
      <button @click="gridview" class="bg-flamingo-900 font-serif rounded-full text-white text-lg">Grid</button>
      <button @click="listview" class="bg-flamingo-800 font-serif rounded-full text-white text-lg">List</button>
    </div>



    <div>

      <div v-if="viewLayout=='grid'">

        <div v-if="rooms" class="grid grid-cols-1 lg:grid-cols-2 mt-16 gap-16">
          <bookable-room-card v-for="room of filteredRooms" :key="room.id" :room="room" @room-calendar="displayCalendar"></bookable-room-card>
        </div>
        <div v-else class=" animate-pulse grid grid-cols-1 md:grid-cols-2 mt-16 gap-16">
          <div v-for="index in 6" :key="index">
            <div class="border border-white shadow rounded-md p-4 ">
              <div class=" space-y-4 py-1">
                <div class="h-4 bg-white rounded w-3/4"></div>
                <div class="space-y-2">
                  <div class="h-4 bg-white rounded"></div>
                  <div class="h-4 bg-white rounded w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-else>

        <div v-if="rooms" class="grid grid-cols-1 lg:grid-cols-1 mt-16 gap-16">
          <list-bookable-room-card v-for="room of filteredRooms" :key="room.id" :room="room" @room-calendar="displayCalendar"></list-bookable-room-card>
        </div>
        <div v-else class=" animate-pulse grid grid-cols-1 md:grid-cols-2 mt-16 gap-16">
          <div v-for="index in 6" :key="index">
            <div class="border border-white shadow rounded-md p-4 ">
              <div class=" space-y-4 py-1">
                <div class="h-4 bg-white rounded w-3/4"></div>
                <div class="space-y-2">
                  <div class="h-4 bg-white rounded"></div>
                  <div class="h-4 bg-white rounded w-5/6"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </container>
</template>

<script>

import Container from "@/components/elements/Container";
import ReservationRepository from "../repositories/ReservationRepository";
import BookableRoomCard from "./elements/reservation/BookableRoomCard";
import ListBookableRoomCard from "./elements/reservation/ListBookableRoomCard";
import InputElement from "./elements/forms/InputElement";

export default {
  name: "CalendarView",
  components: {
    Container, BookableRoomCard, ListBookableRoomCard, InputElement
  },
  data() {
    return {
      rooms: null,
      roomFilter: '',
      viewLayout: ''
    }
  },
  methods: {
    gridview(){
      this.viewLayout='grid'
    },

    listview(){
      this.viewLayout='list'
    },

    displayCalendar(room) {
      this.$store.commit('SET_ROOM', room)
      this.$router.push({name: 'calendar', params: {roomId: room.id}})
    },
    validateSelection(value) {
      this.room = value
      this.getRoomUnavailableDates(value.id)
    },

    getBookableRooms() {
      ReservationRepository.getBookableRooms()
          .then((response => {
            this.rooms = response.data
          })).catch(() => {
      }).finally(
      );
    },
  },
  computed: {
    filteredRooms() {
      return this.rooms.filter(room => {
        if (this.roomFilter)
          return room.name.match(new RegExp(this.roomFilter, 'i'))
              || room.building.name.match(new RegExp(this.roomFilter, 'i'))
        else
          return room
      })
    }
  },
  watch: {},
  mounted() {
    this.getBookableRooms()
  }
}
</script>

<style scoped>


</style>