<template>
  <transition enter-active-class="ease-out duration-300"
              leave-active-class="ease-in duration-200"
              enter-class="opacity-0 "
              enter-to-class="opacity-100 "
              leave-class="opacity-100 "
              leave-to-class="opacity-0 ">

    <div v-if="show" class="fixed z-40 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <transition enter-active-class="ease-out duration-300"
                    leave-active-class="ease-in duration-200"
                    enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
                    enter-to-class="opacity-100 translate-y-0 sm:scale-100 "
                    leave-class="opacity-100 translate-y-0 sm:scale-100 "
                    leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95 "
                    appear>
          <div class="inline-block align-bottom bg-white rounded  text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class=" px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
              <slot name="body"></slot>
            </div>
            <div class="bg-gray-200 rounded-b-lg px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <slot name="footer"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    show: {
      required: true,
      default: false
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>