<template>
  <div class="h-full flex flex-col bg-no-repeat bg-cover bg-fixed" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
    <div class=" px-4 py-8  md:h-32  h-52 w-full  " >
      <page-heading title="" subtitle=""/>
      <div class="text-center text-2xl mt-5 font-serif self-center text-gray-700" v-html="$t('NAV.WELCOME_MESSAGE')"></div>
    </div>

    <div class="flex-grow  w-full  pt-4">
      <container class="text-gray-700" :padding-top="false">
        <h2 class="text-2xl  font-bold text-center">PAGE D'ACCUEIL </h2>
      </container>
    </div>
  </div>
</template>

<script>
import PageHeading from "@/components/elements/PageHeading";

export default {
  name: "Home",
  components: {PageHeading},
  computed: {
    user() {
      return this.$store.getters.user;
    }
  },
  data() {
    return {
      opened: false,
      backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),

      items: [
        {
          id: 0,
          question: 'Question 1',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 1,
          question: 'Question 2',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 2,
          question: 'Question 3',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        },
        {
          id: 3,
          question: 'Question 4',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Harum ipsam, vel. Amet asperiores consectetur excepturi expedita iusto molestiae, nam placeat quidem quis similique sit ullam voluptatem'
        }

      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>