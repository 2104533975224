import store from '../../store/store';

/**
 * Redirects to the login page if the user is not authenticated.
 * @param next
 * @param router
 * @returns {*}
 */
export default function accountActive({ next, router}) {
    if(!store.getters.isAuthenticated)  {
        return router.push({ name: 'login' });
    }

    switch(store.getters.userAccountStatus) {
        case "ACCOUNT_PENDING_EMAIL_VERIFICATION":
            return router.push({ name: 'account-problem.email.unverified' });
        case "ACCOUNT_BLOCKED":
            return router.push({ name: 'account-problem.account.blocked' });
        case "ACCOUNT_PENDING_CONFIRMATION":
            return router.push({ name: 'account-problem.account.pending_confirmation' });
        case "ACCOUNT_REJECTED":
            return router.push({ name: 'account-problem.account.rejected' });
        default:
            return next();
    }
}
