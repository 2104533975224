<template>
  <div id="app">
    <vue-progress-bar></vue-progress-bar>
    <default-layout></default-layout>
    <toast ></toast>
  </div>
</template>

<script>

import DefaultLayout from "@/layouts/DefaultLayout";
import Toast from "./components/elements/notifications/Toast";

export default {
  name: 'App',
  components: {DefaultLayout, Toast},
}
</script>
