<template>
  <div class="bg-gray-100 shadow-lg border-b-2 border-gray-100 mb-9">

    <!-- Authenticated User menu -->
    <nav-link-menu v-if="isAuthenticated">

      <router-link :to="{name: 'home'}" v-slot="{ href, route, navigate, isActive }" custom exact>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.LINK_HOME') }}
        </nav-link>
      </router-link>

      <router-link :to="{name: 'profile'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          <font-awesome-icon icon="exclamation-triangle" class="text-sm mb-0.5 mr-1" :class="[isActive ? 'text-red-400' : 'text-red-400 md:text-red-700']"
                             v-if="!$store.getters.hasCompletedProfile"></font-awesome-icon>
          {{ $t('NAV.LINK_MY_PROFILE') }}
        </nav-link>
      </router-link>

      <router-link :to="{name: 'new-reservation'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.LINK_RESERVATION') }}
        </nav-link>
      </router-link>

<!--      <router-link :to="{name: 'calendar'}" v-slot="{ href, route, navigate, isActive }" custom>-->
<!--        <nav-link :active="isActive" :href="href">-->
<!--          {{ $t('NAV.LINK_CALENDAR') }}-->
<!--        </nav-link>-->
<!--      </router-link>-->

      <router-link :to="{name: 'my-reservations'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.MY_RESERVATIONS') }}
        </nav-link>
      </router-link>
      <router-link :to="{name: 'faq'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.FAQ') }}
        </nav-link>
      </router-link>
    </nav-link-menu>

    <!-- Guest Menu -->
    <nav-link-menu v-else>

      <router-link :to="{name: 'home'}" v-slot="{ href, route, navigate, isActive }" custom exact>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.LINK_HOME') }}
        </nav-link>
      </router-link>

      <router-link :to="{name: 'login'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.LINK_LOGIN') }}
        </nav-link>
      </router-link>

      <router-link :to="{name: 'register'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.LINK_REGISTER') }}
        </nav-link>
      </router-link>
      <router-link :to="{name: 'faq'}" v-slot="{ href, route, navigate, isActive }" custom>
        <nav-link :active="isActive" :href="href">
          {{ $t('NAV.FAQ') }}
        </nav-link>
      </router-link>
    </nav-link-menu>

  </div>
</template>

<script>
import NavLink from "@/layouts/Includes/NavLink";
import NavLinkMenu from "@/layouts/Includes/NavLinkMenu";

export default {
  name: "SubNavbar",
  components: {NavLinkMenu, NavLink},
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  }
}
</script>

<style scoped>

</style>