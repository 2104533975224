<template>
  <div>
    <label class="inline-flex items-center cursor-pointer">
      <input
        id="customCheckLogin"
        type="checkbox"
        class="form-checkbox text-flamingo-800 w-5 h-5 cursor-pointer"
        style="transition: all 0.15s ease 0s;"
        v-model="checked"
        @change="setValue(checked)"
    />
      <span class="ml-2 text-sm font-semibold text-gray-700 select-none">
      {{ name }}
      </span>
    </label>
    <div class="text-red-600 text-xs" v-show="hasError">
      {{ getFirstErrorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxElement",
  data: () => ({
    checked: false
  }),
  props: {
    value: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    validationName: {
      type: String,
      required: false
    }
  },
  watch: {
    value: function (val) {
      this.setValue(val);
    }
  },
  computed: {
    /**
     * Returns the name that is used to send the value to the server.
     * This can be useful for checking for validation errors.
     */
    getValidationName() {
      if(this.validationName !== undefined)
        return this.validationName;

      return this.name.toLowerCase().replace(' ', '_');
    },
    validationErrors() {
      return this.$store.getters.getValidationErrors;
    },
    hasError() {
      return this.getValidationName in this.validationErrors;
    },
    getFirstErrorMessage() {
      if(!this.hasError)
        return "";

      return this.validationErrors[this.getValidationName][0];
    }
  },
  methods: {
    setValue(val) {
      this.checked = val;
      this.$emit('input', val)
    }
  },
}
</script>

<style scoped>

</style>