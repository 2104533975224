import AuthRepository from "@/repositories/AuthRepository";

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

import {vue} from '@/main';
import UserRepository from "../repositories/UserRepository";

const store = new Vuex.Store({
    state: {
        cancelTokens: [],
        successMessage: null,
        errorMessage: null,
        user: null,
        countries: null,
        room: null,
        date: null,
        profiles: null,
        validationErrors: null,
        customer_types: null
    },
    getters: {
        cancelTokens: state => {
            return state.cancelTokens;
        },
        getValidationErrors: state => {
            return state.validationErrors;
        },
        getAuthToken: state => {
            if (state.user === null)
                return null;
            return state.user.auth_token;
        },
        isAuthenticated: state => state.user !== null,
        user: state => state.user,

        getErrorMessage: state => state.errorMessage,
        getSuccessMessage: state => state.successMessage,

        userAccountStatus: state => {
            if (state.user === null)
                return null;

            if (state.user.email_verified_at === null)
                return "ACCOUNT_PENDING_EMAIL_VERIFICATION";
            if (state.user.account_blocked_at !== null)
                return "ACCOUNT_BLOCKED";
            if (state.user.account_rejected_at !== null)
                return "ACCOUNT_REJECTED";
            if (state.user.account_confirmed_at === null)
                return "ACCOUNT_PENDING_CONFIRMATION";

            return "ACCOUNT_ACTIVE";
        },

        userAccountActive: (state, getters) => {
            if (state.user === null)
                return false;

            return getters.userAccountStatus === "ACCOUNT_ACTIVE";
        },

        getCountries: state => state.countries,

        getCustomerTypes: state => state.customer_types,

        hasCompletedProfile: state => {
            if (state.user === null)
                return false
            return state.user.has_completed_profile
        },
        getRoom: state => state.room,
        getDate: state => state.date
    },
    mutations: {
        ADD_CANCEL_TOKEN(state, token) {
            state.cancelTokens.push(token);
        },
        CLEAR_CANCEL_TOKENS(state) {
            state.cancelTokens = [];
        },
        AUTHENTICATE(state, data) {
            state.user = data.user;
        },

        UPDATE_PROFILE(){
            //state.profiles = data
        },

        SAVE_PROFILE(){
            //state.profiles = data
        },

        LOGOUT(state) {
            state.user = null;
            vue.$router.push({name: 'login'});
        },
        SET_VALIDATION_ERRORS(state, errors) {
            state.validationErrors = Object.assign({}, state.validationErrors, errors);
        },
        CLEAR_VALIDATION_ERRORS(state) {
            state.validationErrors = [];
        },
        CLEAR_SINGLE_VALIDATION(state, index) {
            let from = 'from-' + index;
            delete state.validationErrors[from]
            let to = 'to-' + index;
            delete state.validationErrors[to]
            state.validationErrors = Object.assign({}, state.validationErrors)
        },
        SET_SUCCESS_MESSAGE(state, message) {
            state.successMessage = message;
        },
        CLEAR_SUCCESS_MESSAGE(state) {
            state.successMessage = null;
        },
        SET_ERROR_MESSAGE(state, message) {
            state.errorMessage = message;
        },
        CLEAR_ERROR_MESSAGE(state) {
            state.errorMessage = null;
        },
        SET_COUNTIES(state, data) {
            state.countries = data;
        },
        SET_CUSTOMER_TYPES(state, data) {
            state.customer_types = data;
        },
        SET_PROFILES(state, data) {
            state.profiles = data;
        },
        SET_ROOM(state, data) {
            state.room = data
        },
        SET_DATE(state, data) {
            state.date = data
        }
    },
    actions: {
        CANCEL_PENDING_REQUESTS(context) {
            // Cancel all request where a token exists
            context.state.cancelTokens.forEach((request) => {
                if (request.cancel) {
                    request.cancel();
                }
            });

            // Reset the cancelTokens store
            context.commit('CLEAR_CANCEL_TOKENS');
        },

        CLEAR_VALIDATION_ERRORS({commit}) {
            commit('CLEAR_VALIDATION_ERRORS');
        },
        CLEAR_ERROR_MESSAGE({commit}) {
            commit('CLEAR_ERROR_MESSAGE', []);
        },

        LOGIN_USER({commit}, credentials) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                AuthRepository.login(credentials)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('AUTHENTICATE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },

        UPDATE_USER({commit}) {
            return new Promise((resolve, reject) => {
                AuthRepository.getUserData()
                    .then((response => {
                        commit('AUTHENTICATE', response.data);
                        console.log(response.data);
                        resolve(response);
                    })).catch(error => {
                    reject(error);
                });
            })
        },

        EDIT_USER({commit}, payload) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                UserRepository.updateUserData(payload)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('AUTHENTICATE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },
        UPDATE_PROFILE({commit}, payload) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                UserRepository.updateProfile(payload)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('UPDATE_PROFILE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },

        SAVE_PROFILE({commit}, payload) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                UserRepository.saveProfile(payload)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('SAVE_PROFILE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },

        DELETE_PROFILE({commit}, payload) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                UserRepository.deleteProfile(payload)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('UPDATE_PROFILE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },

        REGISTER_USER({commit}, credentials) {
            vue.$Progress.start();
            return new Promise((resolve, reject) => {
                AuthRepository.register(credentials)
                    .then((response => {
                        vue.$Progress.finish();
                        commit('AUTHENTICATE', response.data);
                        resolve(response);
                    })).catch(error => {
                    vue.$Progress.fail();
                    reject(error);
                });
            })
        },

        LOGOUT_USER({commit}) {
            commit('LOGOUT');
        },

        GET_COUNTRIES({commit}) {
            return new Promise((resolve, reject) => {
                UserRepository.getCountries()
                    .then((response => {
                        commit('SET_COUNTIES', response.data);
                        resolve(response);
                    })).catch(error => {
                    reject(error);
                });
            })
        },

        GET_PROFILES({commit}) {
            return new Promise((resolve, reject) => {
                UserRepository.getProfiles()
                    .then((response => {
                        commit('SET_PROFILES', response.data);
                        resolve(response);

                    })).catch(error => {
                    reject(error);
                });
            })
        },

        GET_ALL_PROFILES({commit}) {
            return new Promise((resolve, reject) => {
                AuthRepository.getCustomerTypes()
                    .then((response => {
                        commit('SET_CUSTOMER_TYPES', response.data);
                        resolve(response);

                    })).catch(error => {
                    reject(error);
                });
            })
        },

    },
    plugins: [createPersistedState()],
});

export default store;
