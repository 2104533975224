import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

// Middlewares
import auth from './middlewares/AuthMiddleware';
import profileCompleted from './middlewares/ProfileCompletedMiddleware';
import guest from './middlewares/GuestMiddleware';


import store from "@/store/store";
import Login from "@/components/auth/Login";
import Register from "@/components/auth/Register";
import Home from "@/components/Home";
import Profile from "@/components/Profile";
import NewReservation from "@/components/NewReservation";
import accountActive from "@/router/middlewares/AccountActive";
import AccountProblem from "@/components/auth/accountProblem/AccountProblem";
import AccountProblemEmailUnverified from "@/components/auth/accountProblem/EmailUnverified";
import AccountBlocked from "@/components/auth/accountProblem/AccountBlocked";
import AccountUnconfirmed from "@/components/auth/accountProblem/AccountUnconfirmed";
import MyReservations from "../components/MyReservations";
import MyReservationDetails from "../components/MyReservationDetails";
import ReservationsCalendar from "../components/elements/reservation/ReservationsCalendar";
import MakeReservation from "../components/elements/reservation/MakeReservation";
import StartReservation from "../components/elements/reservation/StartReservation";
import VerifyEmail from "../components/VerifyEmail";
import Error404 from "../components/Error404";
import Faq from "@/components/Faq";

let router = new Router({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/api/*'
        },
        {
            path: '/login',
            name: 'login',
            meta: {
                title: 'Login',
                middleware: guest
            },
            component: Login,
        }, {
            path: '/email/verify/:id/:hash',
            name: 'email-verify',
            meta: {
                title: 'Email Verify',
            },
            component: VerifyEmail,
        },
        {
            path: '/account',
            name: 'account',
            meta: {
                title: 'Account',
                middleware: auth,
            },
            component: AccountProblem,
            children: [
                {
                    path: 'email-unverified',
                    name: 'account-problem.email.unverified',
                    component: AccountProblemEmailUnverified
                },
                {
                    path: 'account-blocked',
                    name: 'account-problem.account.blocked',
                    component: AccountBlocked
                },
                {
                    path: 'account-pending-confirmation',
                    name: 'account-problem.account.pending_confirmation',
                    component: AccountUnconfirmed
                },
                {
                    path: 'account-rejected',
                    name: 'account-problem.account.rejected',
                    component: AccountUnconfirmed
                },
            ]
        },
        {
            path: '/register',
            name: 'register',
            meta: {
                title: 'Register',
                middleware: guest
            },
            component: Register,
        },
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Home',
            },
            component: Home,
        },
        {
            path: '/profile',
            name: 'profile',
            meta: {
                title: 'Profile',
                middleware: accountActive
            },
            component: Profile,
        },
        {
            path: '/new-reservation',
            name: 'new-reservation',
            meta: {
                title: 'New Reservation',
                middleware: [accountActive, profileCompleted]
            },
            component: NewReservation,
        },
        {
            path: '/room/:roomId',
            name: 'calendar-view',
            meta: {
                title: 'Calendar',
                middleware: [accountActive, profileCompleted]
            },
            props: true,
            component: StartReservation,
            children: [
                {
                    path: 'calendar',
                    name: 'calendar',
                    component: ReservationsCalendar,
                    props: true
                }, {
                    path: 'create-reservation',
                    name: 'create-reservation',
                    component: MakeReservation,
                    props: true
                },
            ]
        },
        {
            path: '/my-reservations',
            name: 'my-reservations',
            meta: {
                title: 'My Reservations',
                middleware: [accountActive, profileCompleted]
            },
            component: MyReservations,
        },
        {
            path: '/faq',
            name: 'faq',
            meta: {
                title: 'FAQ'
            },
            component: Faq,
        },
        {
            path: '/my-reservation/:id',
            name: 'my-reservation',
            meta: {
                title: 'My Reservation',
                middleware: [accountActive, profileCompleted]
            },
            component: MyReservationDetails,
        },
        //Catch All
        {
            path: '/*',
            name: 'error-404',
            meta: {
                layout: "error",
                title: 'Not found'
            },
            component: Error404
        }
    ]
});


/*
    Middleware Bootstrap
 */

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({
            ...context,
            next: nextMiddleware
        });
    };
}

router.beforeEach((to, from, next) => {
    store.dispatch('CANCEL_PENDING_REQUESTS');
    store.dispatch('CLEAR_VALIDATION_ERRORS');
    store.commit('SET_SUCCESS_MESSAGE', null);
    store.commit('SET_ERROR_MESSAGE', null);
    if (to.name!== 'create-reservation')
        store.commit('SET_DATE', null)

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title + ' - Commune de Mamer';

    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ?
            to.meta.middleware : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({
            ...context,
            next: nextMiddleware
        });
    }

    return next();
});


export default router;
