<template>
  <div class="">
    <h1 class="text-center text-flamingo-800 text-2xl font-serif my-5">Choisissez vos matériaux nécessaires</h1>
    <div>
      <div
          class="bg-white rounded-md border-2 shadow-sm flex flex-col md:flex-row items-center justify-between cursor-pointer p-5 mb-5 transition hover:shadow-lg"
          :class="item.selected ? 'border-2 border-flamingo-800' : ''"
          v-for="(item , index) of items" :key="index" @click.self="clickedItem(index)">
        <custom-checkbox-element :id="index" :selected="item.selected" v-model="item.selected" @checkbox-clicked="clickedItem(index)"></custom-checkbox-element>
        <img :src="item.thumbnail_url" :alt="item.name" class="cursor-default">
        <p class="m-3 text-center cursor-default flex flex-col">
          <span class="font-bold">{{ item.name }} </span>
          <span> Disponible {{ item.pivot.current_cached_amount }}</span>
        </p>
        <number-selector-element label-class="hidden" class="m-5" :name="'amount' + index " v-model="item.amount" :unsigned="true">
          {{ $t('MAKE_RESERVATION.AMOUNT') }}
        </number-selector-element>
        <action-button class="m-3 relative select-none">
          {{ $t('MAKE_RESERVATION.DETAILS') }}
        </action-button>
      </div>
    </div>
    <p class="bg-olive-100 p-5">{{ $t('MAKE_RESERVATION.ITEM_INFORMATION') }}</p>

  </div>
</template>

<script>
import NumberSelectorElement from "../forms/NumberSelectorElement";
import ActionButton from "../forms/ActionButton";
import CustomCheckboxElement from "../forms/CustomCheckboxElement";

export default {
  name: "MakeReservationSecondStep",
  components: {CustomCheckboxElement, NumberSelectorElement, ActionButton},
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedItems: [],
    }
  },
  methods: {
    clickedItem(index) {
      this.$emit('select-item', index)
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>