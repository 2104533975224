<template>
  <div class="bg-gray-200">
    <container :padding-top="true" class="   ">
      <room-information v-if="room" :room="room" :date="date" ></room-information>
      <transition
          mode="out-in"
          enter-active-class="transition-all transition-fastest ease-out-quad"
          leave-active-class="transition-all transition-faster ease-in-quad"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
          appear
      >
        <router-view></router-view>
      </transition>
    </container>
  </div>
</template>

<script>
import RoomInformation from "@/components/elements/reservation/RoomInformation";
import Container from "@/components/elements/Container";

export default {
  name: "StartReservation",
  components: {RoomInformation, Container},
  props: ['roomId'],
  data() {
    return {
      room: null,
    }
  },
  mounted() {
    this.room = this.$store.getters.getRoom
  },
  computed: {
    date(){
      return this.$store.getters.getDate
    }
  }
}
</script>

<style scoped>

</style>