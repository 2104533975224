<template>
  <div class="flex flex-col h-screen">
    <navbar-component></navbar-component>
    <main class="flex-grow">

      <transition
          mode="out-in"
          enter-active-class="transition-all transition-fastest ease-out-quad"
          leave-active-class="transition-all transition-faster ease-in-quad"
          enter-class="opacity-0 "
          enter-to-class="opacity-100 "
          leave-class="opacity-100 "
          leave-to-class="opacity-0 "
      >
        <router-view></router-view>
      </transition>

    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
import NavbarComponent from "@/layouts/Includes/Navbar";
import FooterComponent from "@/layouts/Includes/Footer";

export default {
  name: "Default",
  components: {NavbarComponent, FooterComponent},
  data: () => ({
    transitionName: 'slide-left'
  }),
  beforeRouteUpdate (to, from, next) {
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    next()
  },
}
</script>

<style scoped>
</style>