<template>
  <div class="text-gray-700 mt-5 w-full">
    <select-element name="profil" class="mb-5" v-model="reservation.profil" :label="$t('MAKE_RESERVATION.PROFIL')"
                    label-class="text-gray-800 text-xl mb-2">
      <option :value="profile.id" v-for="profile in this.profiles" :key="profile.id">
        {{profile.role}} - {{profile.institution_name}}
      </option>
    </select-element>

    <date-time-picker-element
        name="date"
        icon="calendar-alt"
        label-class="text-gray-800 text-xl mb-2"
        :min-date="getMinDate"
        :only-date="true"
        :format="'DD.MM.YYYY'"
        v-model="reservation.date"
        class="mb-5"
    >
      Date
    </date-time-picker-element>
    <div class="grid grid-cols-1 md: grid-cols-2 gap-4">
      <date-time-picker-element
          name="from-"
          icon="calendar-alt"
          label-class="text-gray-800 text-xl mb-2"
          :min-date="getMinDate"
          :only-time="true"
          :format="'HH:mm'"
          v-model="reservation.startTime"
          class="mb-5"
      >
        De
      </date-time-picker-element>
      <date-time-picker-element
          name="to-"
          icon="calendar-alt"
          label-class="text-gray-800 text-xl mb-2"
          :min-date="getMinDate"
          :only-time="true"
          :format="'HH:mm'"
          v-model="reservation.endTime"
          class="mb-5"
      >
        A
      </date-time-picker-element>
    </div>
    <input-element name="title" class="mb-5" type="text" v-model="reservation.title"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.NAME') }}
    </input-element>

    <select-element name="recurrence" class="mb-5" v-model="reservation.recurrence" :label="$t('MAKE_RESERVATION.RECURRENCE.REPEAT')"
                    label-class="text-gray-800 text-xl mb-2">
      <option :value="type.value" v-for="(type ,index) in recurrenceTypes" :key="index">
        <localized-recurrence-dates :date="reservation.date" :type="type.key"></localized-recurrence-dates>
      </option>
    </select-element>

    <checkbox-element v-if="reservation.recurrence !== 'none'" :name="$t('MAKE_RESERVATION.RECURRENCE.NOT_DURING_VACATION')"
                      v-model="reservation.skip_vacations"></checkbox-element>

    <date-time-picker-element
        v-if="reservation.recurrence !== 'none'"
        name="recurrence_end"
        icon="calendar-alt"
        label-class="text-gray-800 text-xl mb-2"
        :min-date="getRecurrenceMinDate"
        :only-date="true"
        :format="'DD.MM.YYYY'"
        v-model="reservation.recurrence_end"
        class="mb-5"
    >
      {{ $t('MAKE_RESERVATION.RECURRENCE.EVENT_ENDS') }}
    </date-time-picker-element>

    <text-area-element name="description" class="mb-5" type="text" v-model="reservation.description"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.DESCRIPTION') }}
    </text-area-element>
    <text-area-element name="comment" class="mb-5" type="text" v-model="reservation.comment"
                   label-class="text-gray-800 text-xl mb-2"
                   @enter="nextStep">{{ $t('MAKE_RESERVATION.COMMENT') }}
    </text-area-element>

    <p class="bg-olive-100 p-5">{{ $t('MAKE_RESERVATION.NO_ENGAGEMENT') }}</p>


  </div>
</template>

<script>
import InputElement from "@/components/elements/forms/InputElement";
import SelectElement from "../forms/SelectElement";
import CheckboxElement from "../forms/CheckboxElement";
import TextAreaElement from "../forms/TextAreaElement";
import DateTimePickerElement from "@/components/elements/forms/DateTimePickerElement";
import LocalizedRecurrenceDates from "./LocalizedRecurrenceDates";

export default {
  name: "MakeReservationFirstStep",
  components: {InputElement, SelectElement, CheckboxElement, DateTimePickerElement, LocalizedRecurrenceDates,TextAreaElement},
  props: {
    reservation: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      recurrenceTypes: [
        {value: 'none', key: 'ONCE'},
        {value: 'daily', key: 'EVERY_DAY'},
        {value: 'weekly', key: 'EVERY_WEEK'},
        {value: 'monthly', key: 'EVERY_MONTH'},
        {value: 'yearly', key: 'EVERY_YEAR'},
        {value: 'every_week_day', key: 'EVERY_WEEK_DAY'}
      ],
      profiles: []
    }
  },
  methods: {
    nextStep() {
      console.log('nextstep');
      this.$emit.nextStep()
    },

    getProfiles() {
      this.$store.dispatch('GET_PROFILES').then(()=>{
        this.profiles = this.$store.state.profiles;
      })
    },
  },
  mounted() {
    this.getProfiles();
  },


  computed: {
    getMinDate() {
      return this.$dayjs().add(7, 'day').hour(0).minute(0).second(0).format('YYYY-MM-DD HH:mm');

    },
    getRecurrenceMinDate() {
      return this.$dayjs(this.reservation.date , 'DD.MM.YYYY').add(1,'day').format('YYYY-MM-DD');
    },
  }
}
</script>

<style scoped>

.date-time-picker {
  font-family: inherit !important;
  margin-bottom: 1.25rem;
}
</style>