 import store from '../../store/store';

/**
 * Redirects to the login page if the user is not authenticated.
 * @param next
 * @param router
 * @returns {*}
 */
export default function auth({ next,  router }) {
    // TODO Implement
    if (!store.getters.hasCompletedProfile)
        return router.push({ name: 'profile' });


    return next();
}
