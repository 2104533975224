<template>
  <action-button @click="logout">
    {{ $t('ACCOUNT_PROBLEMS.LOGOUT') }}
  </action-button>
</template>

<script>
import ActionButton from "@/components/elements/forms/ActionButton";
export default {
  name: "AccountProblemLogoutButton",
  components: {ActionButton},
  methods: {
    logout() {
      this.$store.dispatch('LOGOUT_USER').then(() => {
        this.$router.push({name: 'login'});
      });
    }
  }
}
</script>

<style scoped>

</style>