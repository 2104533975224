<template>
  <button class="font-serif rounded md:text-white text-lg w-full md:w-40 py-1 transition focus:outline-none hover:bg-gray-200 select-none"
          :class="[active ?
          'text-flamingo-800 hover:text-flamingo-900 md:hover:text-white md:bg-flamingo-800 md:hover:bg-flamingo-900' :
          'text-olive-300 hover:text-olive-400 md:hover:text-white md:bg-olive-300 md:hover:bg-olive-400']" @click="$router.push(href).catch(()=>{});">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "NavLink",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    href: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>