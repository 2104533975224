<template>
  <div>
    <date-time-picker-element
        :name="'date-'+index"
        icon="calendar-alt"
        label-class="text-gray-800 text-lg mb-2"
        v-model="mutatedDate"
        :only-date="true"
        :format="'DD.MM.YYYY'"
        class="mb-5"
    >
      {{ $t('NEW_RESERVATION.DATE_START') }}
    </date-time-picker-element>
    <div class="grid grid-cols-2 gap-4">
      <date-time-picker-element
          :name="'from-'+index"
          icon="calendar-alt"
          label-class="text-gray-800 text-lg mb-2"
          v-model="mutatedStartTime"
          :only-time="true"
          :format="'HH:mm'"
          class="mb-5"
      >
        {{ $t('NEW_RESERVATION.DATE_END') }}
      </date-time-picker-element>
      <date-time-picker-element
          :name="'to-'+index"
          icon="calendar-alt"
          label-class="text-gray-800 text-lg mb-2"
          v-model="mutatedEndTime"
          :only-time="true"
          :format="'HH:mm'"
          class="mb-5"
      >
        {{ $t('NEW_RESERVATION.DATE_END') }}
      </date-time-picker-element>
    </div>
  </div>
</template>

<script>
import DateTimePickerElement from "@/components/elements/forms/DateTimePickerElement";
import ReservationRepository from "../../../repositories/ReservationRepository";

export default {
  name: "DateValidation",
  components: {
    DateTimePickerElement
  },
  data() {
    return {
      mutatedDate: this.date,
      mutatedStartTime: this.timeStart,
      mutatedEndTime: this.timeEnd
    }
  },
  props: {
    room: {
      type: Number
    },
    index: {
      type: Number
    },
    date: {
      type: String,
      required: true
    },
    timeStart: {
      type: String,
      required: true
    },
    timeEnd: {
      type: String,
      required: true
    },
  },
  methods: {
    verifyDate() {
      this.$store.commit('CLEAR_SINGLE_VALIDATION', this.index);
      ReservationRepository.verifyDate(this.room, {start_date: this.formattedDateStart, end_date: this.formattedDateEnd, index: this.index})
          .then((response => {
            console.log(response)
          })).catch((error) => {
        this.$store.commit('SET_VALIDATION_ERRORS', error.response.data.errors);
      }).finally(
      );
    }
  },
  watch: {
    formattedDateStart() {
      this.verifyDate()
    },
    formattedDateEnd() {
      this.verifyDate()
    },
    mutatedDate(newVal) {
      this.$emit('update:date', newVal)
    },
    mutatedStartTime(newVal) {
      this.$emit('update:timeStart', newVal)
    },
    mutatedEndTime(newVal) {
      this.$emit('update:timeEnd', newVal)
    }
  },
  computed: {
    formattedDateStart() {
      return this.date + ' ' + this.timeStart
    },
    formattedDateEnd() {
      return this.date + ' ' + this.timeEnd
    },
  },
  mounted() {
    this.verifyDate()
  }
}
</script>

<style scoped>

</style>