<template>
  <div>
    <div class="text-gray-500 text-center text-3xl mb-10 font-serif">
      <small>{{ $t('ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED.TITLE') }}</small>
    </div>

    <p v-html="$t('ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED.TEXT', {email: email})"></p>
    <p v-html="$t('ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED.NOT_RECEIVED', {email: email})" class="mt-4"></p>

    <error-message-display i18n-prefix="ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED" :custom-data="{ date: waitUntilDate }" class="mt-6"></error-message-display>
    <success-message-display class="mt-6"></success-message-display>


    <div class="text-center mt-14">
      <action-button @click="resendVerificationMail" ref="actionButton">
        {{ $t('ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED.BUTTON_RESEND') }}
      </action-button>
    </div>
  </div>
</template>
<script>

import ActionButton from "@/components/elements/forms/ActionButton";
import AuthRepository from "@/repositories/AuthRepository";
import ErrorMessageDisplay from "@/components/elements/ajax/ErrorMessageDisplay";
import SuccessMessageDisplay from "@/components/elements/ajax/SuccessMessageDisplay";
export default {
  name: "AccountProblemEmailUnconfirmed",
  components: {SuccessMessageDisplay, ErrorMessageDisplay, ActionButton},
  data: () => ({
    waitUntilDate: null,
  }),
  methods: {
    resendVerificationMail() {
      this.$refs.actionButton.setLoadingState(true);

      AuthRepository.resetMessages().resendVerificationMail().then(() => {
        this.$store.commit('SET_SUCCESS_MESSAGE', this.$t('ACCOUNT_PROBLEMS.EMAIL_UNVERIFIED.SUCCESSFULLY_RESENT'));
      }).catch((err) => {
        console.log(err);

        if(err.response?.data?.error) {
          if(err.response.data.error === 'ALREADY_VERIFIED') {
            this.$store.dispatch('UPDATE_USER').then(() => {
              console.log("HELLO WASSUP");
              this.$router.push({name: 'home'});
            })
          } else if(err.response.data.error === 'MUST_WAIT') {
            this.waitUntilDate = this.$dayjs(err.response.data.date).format("HH:mm");
          }
        }

      }).finally(() => this.$refs.actionButton.setLoadingState(false));
    }
  },
  computed: {
    email() {
      return this.$store.getters.user.email;
    }
  }
}
</script>
