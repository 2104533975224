import BaseRepository from "@/repositories/BaseRepository";
import PrintRepository from "@/repositories/PrintRepository";

const url = '/reservation';

export default {


    getReservations(start_date, end_date, people) {
        return BaseRepository.get(`${url}/rooms-available`, {
            params: {
                'from': start_date,
                'to': end_date,
                'total_persons': people
            }
        });
    },
    getRoom($roomId) {
        return BaseRepository.get(`${url}/room/${$roomId}`);
    },

    getItemsInRoom($roomId) {
        return BaseRepository.get(`${url}/room/${$roomId}/items`);
    },

    makeReservation(payload) {
        return BaseRepository.post(`${url}/request`, payload);

    },

    getBookableRooms() {
        return BaseRepository.get(`${url}/rooms-bookable`);

    },

    getRoomUnavailableDates($roomId) {
        return BaseRepository.get(`${url}/room/${$roomId}/unavailable`);

    },

    getUserReservations(status) {
        return BaseRepository.get(`${url}`, {
            params: {
                'status': status,
            }
        });
    },
    getReservation(id) {
        return BaseRepository.get(`${url}/${id}`, {});
    },
    printReservation(id) {
        return PrintRepository.get(`${url}/export/${id}`, {responseType: 'arraybuffer'});
    },

    cancelReservation(id) {
        return PrintRepository.get(`${url}/cancel/${id}`, {});
    },


    verifyDate(room, payload) {
        return BaseRepository.post(`${url}/room/${room}/verify-availability`, payload);
    }


}

