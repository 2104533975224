import { render, staticRenderFns } from "./DateView.vue?vue&type=template&id=987e1560&scoped=true&"
import script from "./DateView.vue?vue&type=script&lang=js&"
export * from "./DateView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "987e1560",
  null
  
)

export default component.exports