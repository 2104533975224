<template>
  <div>
    <div class="p-6 text-center">
      <h1 class="text-xl text-center text-flamingo-800 font-bold mb-5">Veuillez télécharger cet document le remplir et l'ajouter a la demande</h1>
      <a class="p-2 hover:text-flamingo-800 hover:underline flex justify-center items-center" target="_blank" :href="document.path">
        <font-awesome-icon icon="file-download" class="" key="static-icon"></font-awesome-icon>
        <span class="mx-2"> {{ document.name }}</span>
      </a>
    </div>
    <div class="bg-white shadow rounded p-6">
      <file-input-element @file="setFile" :value="file" name="file" label-class="text-gray-800 text-xl ">
        Fichier
      </file-input-element>
    </div>
  </div>
</template>

<script>

import FileInputElement from "../forms/FileInputElement";

export default {
  name: "MakeReservationDocumentStep",
  components: {FileInputElement},
  props: {
    document: {
      type: Object,
      required: true
    },
    file: {
      type: File,
    }
  },
  data() {
    return {
      // file: null
    }
  },
  methods: {
    setFile(file) {
      this.$emit('update:file', file)
    }
  }
}
</script>

<style scoped>

</style>