var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-gray-100 shadow-lg border-b-2 border-gray-100 mb-9"},[(_vm.isAuthenticated)?_c('nav-link-menu',[_c('router-link',{attrs:{"to":{name: 'home'},"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_HOME'))+" ")])]}}],null,false,497348025)}),_c('router-link',{attrs:{"to":{name: 'profile'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[(!_vm.$store.getters.hasCompletedProfile)?_c('font-awesome-icon',{staticClass:"text-sm mb-0.5 mr-1",class:[isActive ? 'text-red-400' : 'text-red-400 md:text-red-700'],attrs:{"icon":"exclamation-triangle"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_MY_PROFILE'))+" ")],1)]}}],null,false,3990638046)}),_c('router-link',{attrs:{"to":{name: 'new-reservation'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_RESERVATION'))+" ")])]}}],null,false,1730720494)}),_c('router-link',{attrs:{"to":{name: 'my-reservations'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.MY_RESERVATIONS'))+" ")])]}}],null,false,2331434121)}),_c('router-link',{attrs:{"to":{name: 'faq'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.FAQ'))+" ")])]}}],null,false,3779927423)})],1):_c('nav-link-menu',[_c('router-link',{attrs:{"to":{name: 'home'},"custom":"","exact":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_HOME'))+" ")])]}}])}),_c('router-link',{attrs:{"to":{name: 'login'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_LOGIN'))+" ")])]}}])}),_c('router-link',{attrs:{"to":{name: 'register'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.LINK_REGISTER'))+" ")])]}}])}),_c('router-link',{attrs:{"to":{name: 'faq'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-link',{attrs:{"active":isActive,"href":href}},[_vm._v(" "+_vm._s(_vm.$t('NAV.FAQ'))+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }