<template>
  <button
    class="text-white font-serif w-16 h-16 active:bg-gray-700 text-sm rounded-full shadow transition hover:shadow-lg outline-none focus:outline-none text-center"
    :class="getCustomClasses"
    type="button"
    @click="onButtonClicked">

    <transition name="fade" mode="out-in" class="inline-block">
      <svg :class="getIconTextSize" class="animate-spin inline-block align-middle h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
           viewBox="0 0 24 24" v-if="loading" key="loading-icon">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>

      <font-awesome-icon :icon="icon" class="inline-block align-middle" :class="getIconTextSize" key="static-icon" v-else></font-awesome-icon>
    </transition>
  </button>
</template>

<script>
/**
 * Only allows for an icon and is always in square size.
 */
export default {
  name: "RoundActionButton",
  data: () => ({
    isLoading: false,
  }),
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 16
    },
    variant: {
      type: String,
      default: "primary"
    }
  },
  methods: {
    toggleLoading() {
      this.isLoading = !this.isLoading;
    },
    setLoadingState(state) {
      this.isLoading = state;
    },
    onButtonClicked($event) {
      if (!this.loading)
        this.$emit('click', $event)
    }
  },
  computed: {
    getCustomClasses() {
      let size = this.size;
      let classString = `h-${size} w-${size} `;

      switch(this.variant) {
        case "primary":
          classString += "bg-flamingo-800 hover:bg-flamingo-700";
          break;
        case "secondary":
          classString += "bg-olive-300 hover:bg-olive-400";
          break;
        case "danger":
          classString += "bg-red-500 hover:bg-red-600";
          break;
        case "success":
          classString += "bg-green-500 hover:bg-green-600";
          break;
      }

      return classString;
    },
    getIconTextSize() {
      const s = this.size;
      switch(true) {
        case (s < 6):
          return "text-sm";
        case (s < 10):
          return "text-lg";
        case (s < 14):
          return "text-xl";
        default:
          return "text-2xl";
      }
    },
    loading() {
      return this.isLoading;
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .05s ease;
}

.fade-enter, .-fade-leave-to {
  opacity: 0;
}
</style>