<template>
  <transition name="slide-fade">
    <div class="bg-green-400 text-white font-bold p-4 rounded shadow-lg text-center" v-if="successMessage !== null">
      {{ successMessage }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "SuccessMessageDisplay",
  props: {
    i18nPrefix: {
      type: String,
      required: false
    },
    customData: {
      type: Object,
      required: false,
    }
  },
  computed: {
    hasI18nPrefix() {
      return this.i18nPrefix;
    },
    hasCustomData() {
      return this.customData !== undefined;
    },
    successMessage() {
      let message = this.$store.getters.getSuccessMessage;
      if(message === null)
        return null;

      if(this.hasI18nPrefix) {
        if(this.hasCustomData)
          return this.$t(this.i18nPrefix + '.ERROR_KEYS.' + message, this.customData);

        return this.$t(this.i18nPrefix + '.ERROR_KEYS.' + message);
      }

      return message;
    },
  }
}
</script>

<style scoped>

</style>