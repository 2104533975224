import store from '../../store/store';

/**
 * Redirects to the Dashboard if the user tries to access a view that is only allowed to guests.
 * @param next
 * @param router
 * @returns {*}
 */
export default function guest({ next, router}) {
    if(store.getters.isAuthenticated)
        return router.push({ name: 'profile' });

    return next();
}
