import BaseRepository from "@/repositories/BaseRepository";
import store from "@/store/store";

const url = '';

export default {
    resetMessages() {
        store.commit('SET_ERROR_MESSAGE', null);
        store.commit('SET_SUCCESS_MESSAGE', null);
        return this;
    },

    login(credentials) {
        return BaseRepository.post(`${url}/auth/login`, {
            email: credentials.email,
            password: credentials.password
        })
    },

    getUserData() {
        return BaseRepository.get(`${url}/account`);
    },

    getCustomerTypes() {
      return BaseRepository.get(`${url}/auth/customer-types`);
    },

    /**
     * Request a new verification mail.
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    resendVerificationMail() {
        return BaseRepository.post(`${url}/auth/send-verification-mail`);
    },

    register(payload) {
        return BaseRepository.post(`${url}/auth/register`, payload);
    },

    verifyEmail(id, hash){
        return BaseRepository.get(`${url}/auth/verify-email/${id}/${hash}`);
    }
}