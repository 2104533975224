<template>
  <transition name="slide-fade">
    <div class="bg-red-400 text-white font-bold p-4 rounded shadow-lg text-center" v-if="errorMessage !== null">
      {{ errorMessage }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "ErrorMessageDisplay",
  props: {
    i18nPrefix: {
      type: String,
      required: false
    },
    customData: {
      type: Object,
      required: false,
    }
  },
  computed: {
    hasI18nPrefix() {
      return this.i18nPrefix;
    },
    hasCustomData() {
      return this.customData !== undefined;
    },
    errorMessage() {
      let error = this.$store.getters.getErrorMessage;
      if(error === null)
        return null;

      if(this.hasI18nPrefix) {
        if(this.hasCustomData)
          return this.$t(this.i18nPrefix + '.ERROR_KEYS.' + error, this.customData);

        return this.$t(this.i18nPrefix + '.ERROR_KEYS.' + error);
      }

      return error;
    },
  }
}
</script>

<style scoped>

</style>