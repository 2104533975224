<template>
  <div>
    <p class="bg-olive-100 p-5 mb-5">{{ $t('CALENDAR.INFORMATION') }}</p>
    <vue-cal
        style="height: 700px"
        class="rounded-md bg-white  border-transparent shadow-lg"
        locale="fr"
        :selected-date="minDate"
        :disable-views="['years', 'year']"
        active-view="week"
        :min-date="minDate"
        :time-from="8 * 60"
        :time-to="22 * 60"
        :time-step="15"
        :events="dates"
        :editable-events="{ title: false, drag: false, resize: false, delete: true, create: true }"
        :drag-to-create-threshold="15"
        :on-event-create="onEventCreate"
        @event-drag-create="createReservation"
        :snap-to-time="15"
    />
  </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import '../../../locales/fr.js'
import ReservationRepository from "../../../repositories/ReservationRepository";

export default {
  name: "ReservationsCalendar",
  components: {VueCal},
  props: ['roomId'],
  data() {
    return {
      dates: [],
      room: null,
      date_start: null,
      date_end: null,
      time_start: null,
      time_end: null,
      selectedEvent: null,
    }
  },
  methods: {
    getRoomUnavailableDates() {
      ReservationRepository.getRoomUnavailableDates(this.roomId)
          .then((response => {
            this.dates = response.data.reserved.concat(response.data.blocked)
          })).catch(() => {
      }).finally(
      );
    },
    createReservation(event) {
      for (const date of this.dates) {
        if ((this.selectedEvent.end >= new Date(date.start) && this.selectedEvent.start <= new Date(date.start))
            || this.selectedEvent.end >= new Date(date.end) && this.selectedEvent.start <= new Date(date.start)
            || this.selectedEvent.end >= new Date(date.end) && this.selectedEvent.start <= new Date(date.end)
        ) {
          this.deleteEventFunction()
          return false
        }
      }
      this.date_start = event.start.format('DD.MM.YYYY')
      this.time_start = event.start.format('HH:mm')
      this.time_end = event.end.format('HH:mm')
      //this.date_end = event.end.format('DD.MM.YYYY HH:mm')
      this.$store.commit('SET_DATE', {date: this.date_start, timeStart: this.time_start, timeEnd: this.time_end})
      this.$router.push({name: 'create-reservation'})

    },
    onEventCreate(event, deleteEventFunction) {
      this.selectedEvent = event
      this.deleteEventFunction = deleteEventFunction
      return event

    }
  },
  computed: {
    minDate() {
      return new Date().addDays(7)
    },
  },
  watch: {},
  mounted() {
    this.getRoomUnavailableDates()
  }
}
</script>

<style scoped>

</style>