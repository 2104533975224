<template>
  <footer class="w-full py-3 bg-flamingo-800 px-6 z-30">

    <div class="flex flex-wrap h-full">

      <div class="w-full md:w-6/12 px-4 text-xl font-serif">
        <div class="text-white py-1 font-serif">
          Copyright © {{ date }}
          <a
              href="https://mamer.lu"
              class="text-olive-300 hover:text-olive-200 font-bolder py-1">
            {{ $t('FOOTER.APP_NAME') }}
          </a>
        </div>
      </div>

      <div class="w-full md:w-6/12 px-4">

        <div class="flex flex-wrap md:justify-end">
          <div class="text-white font-serif text-xl py-1 flex flex-col md:flex-row">

            <a href="https://www.mamer.lu" target="_blank"
               class="px-0 lg:px-3 hover:text-gray-200">Mamer.lu</a>

            <span class="px-1 hidden md:block">&middot;</span>

            <a href="http://www.mamer.lu/wp-content/uploads/2020/05/Mamer_RGPD-Politique-Confidentialite_20191107.pdf" target="_blank"
               class="px-0 lg:px-3 hover:text-gray-200">{{ $t('FOOTER.GDPR') }}</a>

          </div>

        </div>
      </div>

    </div>

  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>
