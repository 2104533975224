<template>
  <div class="">
    <div class="my-10 w-full relative flex justify-content-around ">
      <div class="absolute z-2  bg-flamingo-800  left-0 rounded-full h-full transition-spacing duration-300 ease-in-out w-full "
           :class="[headerWidth ]">
      </div>

      <div v-for="(link,index) of navigation" :key="index+1" class="p-2 z-10 text-xl flex-1 text-center relative  justify-center  transition duration-300"
           :class="step === link ? ' block text-white' : 'hidden md:block text-flamingo-800 opacity-50'">
        <span class=" block text-center left-3 font-bold ">{{ index + 1 }} /{{ navigation.length }}</span>
        <span class="block text-center"> {{ $t('MAKE_RESERVATION.' + link) }}</span>
      </div>
    </div>
    <transition
        mode="out-in"
        enter-active-class="transition-all transition-fastest ease-out-quad"
        leave-active-class="transition-all transition-faster ease-in-quad"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-class="opacity-100 "
        leave-to-class="opacity-0 "
    >
      <keep-alive>
        <make-reservation-first-step v-if="step === 'DEMAND'" :reservation="reservation"></make-reservation-first-step>
        <make-reservation-second-step v-else-if="step === 'RENTAL'" :items="items" @select-item="selectItem"></make-reservation-second-step>
        <make-reservation-document-step v-else-if="step === 'DOCUMENT'" :file.sync="file" :document="room.document"></make-reservation-document-step>
        <make-reservation-final-step v-else-if="step === 'SUMMARY'" :reservation="reservation" :items="items"></make-reservation-final-step>
      </keep-alive>
    </transition>
    <div class="text-center my-5">
      <action-button v-if="step !== 'DEMAND'" @click="previousStep">
        <font-awesome-icon icon="arrow-left" class="inline-block align-middle" key="static-icon"></font-awesome-icon>
        <span class="pl-3">{{ $t('MAKE_RESERVATION.PREVIOUS') }}</span>
      </action-button>
      <action-button v-if="step !== 'SUMMARY'" @click="nextStep">
        <span class="pr-3">{{ $t('MAKE_RESERVATION.NEXT') }}</span>
        <font-awesome-icon icon="arrow-right" class="inline-block align-middle" key="static-icon"></font-awesome-icon>
      </action-button>
      <action-button v-else @click="makeReservation">
        <span class="">{{ $t('MAKE_RESERVATION.SEND') }}</span>
      </action-button>
    </div>
    <modal :show="showModal">
      <template v-slot:body>
        <p> {{ $t('MAKE_RESERVATION.DATE_ISSUES') }}</p>
        <p class="mt-2 text-xl">{{ $t('MAKE_RESERVATION.UNAVAILABLE_DATES') }}</p>
        <div class="py-2 border-b-2" v-for="(date, index) of unavailableDates" :key="index">
          <date-validation :room="room.id" :index="index" :date.sync="date.date" :time-start.sync="date.start_time"
                           :time-end.sync="date.end_time"></date-validation>
        </div>
      </template>sdqs
      <template v-slot:footer>
        <button type="button"
                @click="reserveWithoutDates"
                class="w-full inline-flex justify-center rounded-md border border-transparent
                shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          Ignorer
        </button>
        <button type="button"
                @click="reserveWithChangedDates"
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300
                shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
          Réserver
        </button>
      </template>
    </modal>
  </div>

</template>

<script>
import MakeReservationFirstStep from "./MakeReservationFirstStep";
import MakeReservationSecondStep from "./MakeReservationSecondStep";
import MakeReservationDocumentStep from "./MakeReservationDocumentStep";
import MakeReservationFinalStep from "./MakeReservationFinalStep";
import ActionButton from "@/components/elements/forms/ActionButton";
import ReservationRepository from "../../../repositories/ReservationRepository";
import Modal from "../notifications/Modal";
import DateValidation from "./DateValidation";

export default {
  name: "MakeReservation",
  components: {MakeReservationFirstStep, MakeReservationSecondStep, MakeReservationDocumentStep, MakeReservationFinalStep, ActionButton, Modal, DateValidation},
  data() {
    return {
      step: 'DEMAND',
      reservation: {
        date: '',
        startTime: '',
        endTime: '',
        title: '',
        recurrence: 'none',
        recurrence_end: '',
        skip_vacations: false,
        description: '',
        comment: '',
        profil:''
      },
      file: null,
      showModal: false,
      unavailableDates: null,
      dateUnavailable: false,
      items: [],
      room: null,
      date: null
    }
  },
  mounted() {
    this.getProfiles();
  },
  methods: {

    getProfiles() {
      this.$store.dispatch('GET_PROFILES').then(()=>{
        this.profiles = this.$store.getters.getProfiles;
      })
    },

    nextStep() {
      let errors = {}
      if (this.reservation.title.length < 3)
        Object.assign(errors, {title: ["Le nom de l'évènement est requis "]})
      if (this.reservation.recurrence !== 'none' && this.reservation.recurrence_end.length < 1)
        Object.assign(errors, {recurrence_end: ["La Date est requis "]})

      if (this.step === 'DEMAND' && Object.keys(errors).length)
        this.$store.commit('SET_VALIDATION_ERRORS', errors);
      else if (this.step === 'RENTAL' && this.itemSelectedAndEmptyAmount)
        this.$store.commit('SET_VALIDATION_ERRORS', this.getItemsAmountMissing());
      else {
        this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
        this.step = this.navigation[this.navigation.indexOf(this.step) + 1]
      }

    },
    previousStep() {
      if (this.step !== 'DEMAND')
        this.step = this.navigation[this.navigation.indexOf(this.step) - 1]
    },

    getItemsAmountMissing() {
      let errors = {};
      this.items.map(function (item, index) {
        if (item.selected && (!item.amount || item.amount < 1))
          errors['amount' + index] = ['Le montant est nécessaire']
      })
      return errors;
    },
    selectItem(index) {
      this.$set(this.items[index], 'selected', !this.items[index].selected)
    },
    getItemsInRoom() {
      ReservationRepository.getItemsInRoom(this.room.id)
          .then((response => {
            this.items = response.data
          })).catch(() => {
      }).finally(
      );
    },

    makeReservation() {
      this.reservation.items = []
      this.items.forEach($item => {
        if ($item.selected)
          this.reservation.items.push($item)
      })
      const data = new FormData()
      data.append('start_date', this.formattedDateStart)
      data.append('end_date', this.formattedDateEnd)
      data.append('room_id', this.room.id)
      data.append('items', JSON.stringify(this.reservation.items))
      if (this.file)
        data.append('document', this.file)
      data.append('title', this.reservation.title)
      data.append('recurrence', this.reservation.recurrence)
      data.append('profil', this.reservation.profil)
      data.append('recurrence_end', this.reservation.recurrence_end)
      data.append('skip_vacations', this.reservation.skip_vacations)
      data.append('description', this.reservation.description)
      data.append('comment', this.reservation.comment)
      if (this.reservation.new_dates)
        data.append('new_dates', JSON.stringify(this.reservation.new_dates))
      ReservationRepository.makeReservation(data)
          .then((response => {
            this.$router.push({name: 'my-reservation', params: {id: response.data.reservation}})
          })).catch((e) => {
        if (e.response.data.unavailable) {
          this.unavailableDates = e.response.data.unavailable
          this.reservation.new_dates = e.response.data.available
          this.showModal = true
        }
      }).finally(
      );
    },
    verifyDate() {
      this.$store.commit('CLEAR_VALIDATION_ERRORS');
      ReservationRepository.verifyDate(this.room.id, {start_date: this.formattedDateStart, end_date: this.formattedDateEnd})
          .then((() => {
          })).catch((error) => {
        this.$store.commit('SET_VALIDATION_ERRORS', error.response.data.errors);
      }).finally(
      );
    },
    reserveWithChangedDates() {
      this.reservation.new_dates = this.reservation.new_dates.concat(this.unavailableDates)
      this.makeReservation()
    },
    reserveWithoutDates() {
      this.reservation.ignore_conflict = true
      this.makeReservation()
    }
  },
  created() {
    this.room = this.$store.getters.getRoom
    this.date = this.$store.getters.getDate
    this.getItemsInRoom()
    this.reservation.date = this.date.date
    this.reservation.startTime = this.date.timeStart
    this.reservation.endTime = this.date.timeEnd
  },
  watch: {
    formattedDateStart() {
      this.verifyDate()
    },
    formattedDateEnd() {
      this.verifyDate()
    },
    unavailableDates() {
    }, deep: true
  },
  computed: {
    itemSelectedAndEmptyAmount() {
      for (const item of this.items) {
        if (item.selected && (!item.amount || item.amount < 0)) {
          return true
        }
      }
      return false
    },
    formattedDateStart() {
      return this.reservation.date + ' ' + this.reservation.startTime
    },
    formattedDateEnd() {
      return this.reservation.date + ' ' + this.reservation.endTime
    },
    navigation() {
      let nav = ['DEMAND', 'SUMMARY']
      if (this.room.document)
        nav.splice(1, 0, 'DOCUMENT')
      if (this.items.length > 0)
        nav.splice(1, 0, 'RENTAL')
      return nav
    },
    headerWidth() {
      if (this.navigation.length === 2) {
        if (this.step === 'SUMMARY')
          return ' md:w-1/2 md:left-1/2'
        return 'md:w-1/2'
      }
      if (this.navigation.length === 3) {
        if (this.step === 'DOCUMENT' || this.step === 'RENTAL')
          return ' md:w-1/3 md:left-1/3'
        if (this.step === 'SUMMARY')
          return ' md:w-1/3 md:left-2/3'
        return 'md:w-1/3'
      } else if (this.navigation.length === 4) {
        if (this.step === 'RENTAL')
          return ' md:w-1/4 md:left-1/4'
        if (this.step === 'DOCUMENT')
          return ' md:w-1/4 md:left-2/4'
        if (this.step === 'SUMMARY')
          return ' md:w-1/4 md:left-3/4'
        return 'md:w-1/4'

      }
      return ''
    },
  }
}
</script>

<style scoped>

</style>