import Vue from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


Object.defineProperties(Vue.prototype, {
    $dayjs: {
        get() {
            return dayjs
        }
    }
});