<template>
  <nav class="z-30 w-full bg-gray-50 ">
    <div :class="[showSimpleNavBg ? 'city-bg-simple' : 'city-bg-simple']">

      <div class="flex flex-row items-center md:items-start justify-between px-4 pt-4 pb-6" :class="[showSimpleNavBg ? 'h-32' : 'h-32']">

        <a class="text-sm font-bold leading-relaxed inline-block mr-4  whitespace-nowrap uppercase text-black ml-4" href="https://mamer.lu" target="_blank">
          <img src="@/assets/images/logos/logo-simple-shadow.png" alt="Commune de Mamer" class="h-18">
        </a>

        <!--        <div v-if="!showSimpleNavBg" class="hidden lg:block text-2xl font-serif self-center text-gray-700" v-html="$t('NAV.WELCOME_MESSAGE')"></div>-->

        <div v-if="$store.getters.isAuthenticated" class="mr-4 text-right block">
          <span class="hidden md:inline-block text-flamingo-800 text-2xl font-serif mr-4">
            {{ $t('NAV.WELCOME_USER', {name: $store.getters.user.first_name}) }}
          </span>
          <span class="p-5 cursor-pointer text-flamingo-800 hover:text-flamingo-900" @click="signOut">
            <font-awesome-icon icon="sign-out-alt" class="text-3xl md:text-xl   "></font-awesome-icon>
          </span>
        </div>
        <div v-else class="mr-4 text-right block"></div>

      </div>

      <div class="h-8 w-full bg-flamingo-800"></div>
    </div>
    <sub-navbar v-if="showSubNav"></sub-navbar>
  </nav>
</template>

<script>
import SubNavbar from "@/layouts/Includes/SubNavbar";

export default {
  components: {SubNavbar},
  methods: {
    signOut() {
      this.$store.dispatch('LOGOUT_USER').then(() => {
        //this.$router.push({name: 'login'});
      });
    }
  },
  computed: {
    showSimpleNavBg() {
      return ['login', 'register'].includes(this.$route.name);
    },
    showSubNav() {
      if (this.$store.getters.isAuthenticated) {
        return this.$store.getters.userAccountActive;
      }
      return true;
    }
  }
}
</script>