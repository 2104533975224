<template>
  <div class=" bg-white flex flex-col md:flex-row md:gap-8 justify-center py-3 absolute w-full">
    <!-- Mobile nav -->
    <div class="block md:hidden">
      <div class="text-xl text-flamingo-800 hover:text-flamingo-900 text-right mr-4 cursor-pointer select-none" @click="showContent = !showContent">
        <font-awesome-icon icon="times" v-if="showContent" />
        <font-awesome-icon icon="bars" v-else />
      </div>

      <div v-if="showContent">
        <slot></slot>
      </div>
    </div>


    <!-- Desktop Nav -->
    <div class="hidden md:block space-x-6">
      <slot></slot>
    </div>

  </div>
</template>

<script>
export default {
  name: "NavLinkMenu",
  data: () => ({
    showContent: false,
  }),
  watch: {
    '$route' () {
      this.showContent = !this.showContent
    }
  }
}
</script>

<style scoped>

</style>