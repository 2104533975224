<template>
  <section class="w-full h-full bg-white bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">

        <div class="w-full md:w-6/12 lg:w-4/12 px-4 mt-8">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-flamingo-50 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-6">

              <div class="text-gray-500 text-center text-3xl mb-10 font-serif">
                <small>{{ $t('LOGIN.TITLE') }}</small>
              </div>

              <form class="mt-4">
                <div class="relative w-full mb-5">

                  <error-message-display class="mb-3"/>

                  <input-element name="email" icon="envelope" autocomplete="email" type="email" v-model="email" @enter="login">
                    {{ $t('LOGIN.EMAIL') }}
                  </input-element>

                </div>
                <div class="relative w-full">
                  <input-element name="password" icon="lock" autocomplete="current-password" type="password" v-model="password" @enter="login">
                    {{ $t('LOGIN.PASSWORD') }}
                  </input-element>
                </div>
                <div class="text-center mt-14">
                  <action-button @click="login" ref="loginButton">
                    {{ $t('LOGIN.LOGIN_BUTTON') }}
                  </action-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import InputElement from "@/components/elements/forms/InputElement";
import ActionButton from "@/components/elements/forms/ActionButton";
import ErrorMessageDisplay from "@/components/elements/ajax/ErrorMessageDisplay";

export default {
  name: "login-page",
  data: () => ({
    backgroundImagePath: require('@/assets/images/auth/mamerbg.jpg'),

    email: '',
    password: '',
  }),
  components: {
    ErrorMessageDisplay,
    ActionButton,
    InputElement
  },
  methods: {
    login() {
      this.$refs.loginButton.setLoadingState(true);
      this.$store.dispatch('CLEAR_VALIDATION_ERRORS');
      this.$store.dispatch('CLEAR_ERROR_MESSAGE');

      this.$store.dispatch("LOGIN_USER", {
        email: this.email,
        password: this.password
      }).then(() => {
        this.$refs.loginButton.setLoadingState(false);
        this.$router.push({ name: 'profile' });
      }).catch(() => {
        this.password = "";
        this.$refs.loginButton.setLoadingState(false)
      })

    }
  }
}
</script>
