<template>
  <section class="w-full h-full bg-white bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">

        <div class="w-full lg:w-6/12 px-4 mt-8">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-flamingo-50 border-0">
            <div class="flex-auto px-4 lg:px-10 py-10 pt-6">

              <router-view></router-view>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import store from "@/store/store";

export default {
  name: "AccountProblem",
  data: () => ({
    backgroundImagePath: require('@/assets/images/auth/mamerbg.jpg'),
  }),
  mounted() {
    this.checkUserAccount();
  },
  methods: {
    checkUserAccount() {
      this.$store.dispatch("UPDATE_USER").then(() => {
        switch(store.getters.userAccountStatus) {
          case "ACCOUNT_PENDING_EMAIL_VERIFICATION":
            return this.$router.push({ name: 'account-problem.email.unverified' });
          case "ACCOUNT_BLOCKED":
            return this.$router.push({ name: 'account-problem.account.blocked' });
          case "ACCOUNT_PENDING_CONFIRMATION":
            return this.$router.push({ name: 'account-problem.account.pending_confirmation' });
          case "ACCOUNT_REJECTED":
            return this.$router.push({ name: 'account-problem.account.rejected' });
          default:
            return this.$router.push({ name: 'home' });
        }
      });
    }
  }
}
</script>
