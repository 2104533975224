import store from '../../store/store';

/**
 * Redirects to the login page if the user is not authenticated.
 * @param next
 * @param router
 * @returns {*}
 */
export default function auth({ next, router}) {
    if(!store.getters.isAuthenticated)  {
        return router.push({ name: 'login' });
    }

    return next();
}
