<template>
  <div class="rounded pl-4 pr-4 pt-1 pb-1 text-white text-center" :class="getCustomClasses">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  computed: {
    getCustomClasses() {
      switch (this.variant) {
        case "primary":
          return "bg-flamingo-800"
        case "secondary":
          return "bg-olive-300";
        case "success" :
          return " bg-green-500"
        case "failed" :
          return " bg-red-500"
      }
      return "bg-flamingo-800";
    }
  }
}
</script>

<style scoped>

</style>