<template>
  <div class=" text-center text-flamingo-800 flex flex-col justify-center h-full  bg-no-repeat bg-cover" :style="{'background-image': 'url(' + backgroundImagePath + ')'}">
    <p class=" font-bold  text-6xl"> Page not found</p>
    <p class="text-2xl py-5"> We Couldn't find the page your are looking for</p>
    <router-link :to="{name: 'home'}" class="underline">
      Back Home
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Error404",
  data() {
    return {
      backgroundImagePath: require('@/assets/images/auth/bg-light.jpg'),

    }
  }
}
</script>

<style scoped>

</style>